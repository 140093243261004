import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, ListItemIcon, MenuItem } from "@material-ui/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import PropTypes from "prop-types";
import { ActionsAfterSearch } from "../utils/constants";
import { useHistory } from "react-router-dom";

const SimSwap = (props) => {
  const history = useHistory();
  const { msisdn, setLoading } = props;
  const [newSim, setNewSim] = useState();
  const [imei, setImei] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const simSwap = () => {
    setLoading(true);
    axios
      .get(`/line/${msisdn}/sim-swap`, {
        params: { new_sim: newSim, imei },
      })
      .then(({ data }) => {
        history.push(
          `/search-line/${data.uuid}/status?action=${ActionsAfterSearch.SIM_SWAP}&new_sim=${newSim}`
        );
      })
      .catch((error) => {
        alert(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon alignItems="flex-start">
          <SwapHorizIcon fontSize="small" color="primary" />
        </ListItemIcon>
        Sim Swap
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Change SIM for {msisdn}
        </DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}

          <DialogContentText>Please enter new sim number.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Sim"
            fullWidth
            value={newSim}
            onChange={(e) => setNewSim(e.target.value)}
          />
          <TextField
            margin="dense"
            id="imei"
            label="IMEI (Optional)"
            fullWidth
            value={imei}
            onChange={(e) => setImei(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={simSwap} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

SimSwap.propTypes = {
  msisdn: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default SimSwap;
