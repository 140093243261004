import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  Fade,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import PeopleIcon from "@material-ui/icons/People";
import SearchIcon from "@material-ui/icons/Search";
import ListIcon from "@material-ui/icons/List";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import UpdateIcon from "@material-ui/icons/Update";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SimCardIcon from "@material-ui/icons/SimCard";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";

const CustomNavButton = (props) => {
  let history = useHistory();
  return (
    <Button
      {...props}
      component={Link}
      variant={
        history.location.pathname + history.location.search === props.to
          ? "contained"
          : "outlined"
      }
      color="secondary"
    >
      {props.children}
    </Button>
  );
};

export default function Header() {

  const [userData, setUserData] = useState();
  const [notificationsCount, setNotificationsCount] = useState(0);

  const getNotifications = () => {
    axios
      .get("/notifications", { params: { is_read: false } })
      .then((response) => {
        console.log(response.data);
        setNotificationsCount(response.data.total);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`; // for all requests
    
    }

    if (localStorage.getItem("userData") !== null) {
      setUserData(JSON.parse(localStorage.getItem("userData")));
    } else {
      axios.get("/user/me").then((response) => {
      
        setUserData(response.data);
        localStorage.setItem("userData", JSON.stringify(response.data));
      });
    }
    // getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar position="static" justifyContent="center">
      <Box pt={1} pb={1}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">Linecheck</Typography>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
                alignItems="center"
              >
                {/* <Grid item>
                  <Tooltip title="Notifications">
                    <IconButton
                      onClick={() => {
                        history.push("/notifications");
                      }}
                      size="medium"
                    >
                      <Badge
                        color="secondary"
                        badgeContent={notificationsCount}
                      >
                        <NotificationsIcon color="secondary" />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Grid> */}
                <Grid item>
                  <CustomNavButton startIcon={<SearchIcon />} to="/search-line">
                    Search Line
                  </CustomNavButton>
                </Grid>
                <Grid item>
                  <CustomNavButton
                    startIcon={<ListIcon />}
                    to="/lines-data-usage"
                  >
                    Lines Data Usage
                  </CustomNavButton>
                </Grid>

                <Grid item>
                  <CustomNavButton
                    startIcon={<UpdateIcon />}
                    to="/update-lines"
                  >
                    Update Lines
                  </CustomNavButton>
                </Grid>

                {userData &&
                  (userData.is_org_manager || userData.is_super_admin) && (
                    <React.Fragment>
                      <Grid item>
                        <CustomNavButton
                          startIcon={<ListIcon />}
                          to="/all-lines"
                        >
                          All Lines
                        </CustomNavButton>
                      </Grid>

                      <Grid item>
                        <CustomNavButton
                          startIcon={<PeopleIcon />}
                          to="/carrier-accounts"
                        >
                          Carrier Accounts
                        </CustomNavButton>
                      </Grid>
                    </React.Fragment>
                  )}

                {userData &&
                  (userData.is_org_manager ||
                    userData.is_super_admin ||
                    userData.is_client_manager) && (
                    <React.Fragment>
                      <Grid item>
                        <CustomNavButton
                          startIcon={<AssignmentIndIcon />}
                          to="/clients"
                        >
                          Clients
                        </CustomNavButton>
                      </Grid>
                    </React.Fragment>
                  )}

                {userData && userData.is_client_manager && (
                  <React.Fragment>
                    <Grid item>
                      <CustomNavButton
                        startIcon={<PeopleIcon />}
                        to={`/client/${userData.client_uuid}/users?client=1`}
                      >
                        Users
                      </CustomNavButton>
                    </Grid>
                  </React.Fragment>
                )}

                {userData && userData.is_super_admin && (
                  <React.Fragment>
                    <Grid item>
                      <CustomNavButton
                        startIcon={<SimCardIcon />}
                        to={`/sim-check`}
                      >
                        Sim Check
                      </CustomNavButton>
                    </Grid>

                    <Grid item>
                      <CustomNavButton
                        startIcon={<SwapHorizontalCircleIcon />}
                        to={`/sim-swap`}
                      >
                        Sim Swap
                      </CustomNavButton>
                    </Grid>
                  </React.Fragment>
                )}

                <Grid item>
                  <ProfileIconComponent userData={userData} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

const ProfileIconComponent = (props) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        color="secondary"
        startIcon={<AccountCircleIcon />}
        onClick={handleClick}
        variant="outlined"
      >
        {props.userData?.full_name}
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            history.push("/profile");
          }}
        >
          <ListItemIcon>
            <PeopleIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Profile</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.clear();
            history.push("/");
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
