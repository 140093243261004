import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import Layout from "../layouts/Layout";
import { isSuperAdmin, isClientManger, isOrgManager } from "../auth/roles";
import CopyToClipboard from "../components/CopyToClipboard";
import moment from "moment";
import DateAndTime from "../components/DateAndTime";
import Status from "../components/Status";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useHistory } from "react-router-dom";
import { ActionsAfterSearch } from "../utils/constants";
import RestoreIcon from "@material-ui/icons/Restore";
import ListIcon from "@material-ui/icons/List";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CopyToClipboardDataGridHeader from "../components/CopyToClipboardDataGridHeader";
import SimSwap from "./SimChange";

const LineActionComponent = (props) => {
  const history = useHistory();

  const { data, setLoading } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const refreshLine = (line_id) => {
    setLoading(true);
    axios
      .get(`/line/${line_id}/refresh`)
      .then(({ data }) => {
        history.push(
          `/search-line/${data.uuid}/status?action=${ActionsAfterSearch.REFRESH}`
        );
      })
      .catch((error) => {
        alert(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchLine = (line_id) => {
    setLoading(true);
    axios
      .get(`/line/${line_id}/search`)
      .then(({ data }) => {
        history.push(`/search-line/${data.uuid}/status`);
      })
      .catch((error) => {
        alert(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const restoreLine = (line_id) => {
    setLoading(true);
    axios
      .get(`/line/${line_id}/restore`)
      .then(({ data }) => {
        history.push(
          `/search-line/${data.uuid}/status?action=${ActionsAfterSearch.RESTORE}`
        );
      })
      .catch((error) => {
        alert(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const suspendLine = (line_id) => {
    setLoading(true);
    axios
      .get(`/line/${line_id}/suspend`)
      .then(({ data }) => {
        history.push(
          `/search-line/${data.uuid}/status?action=${ActionsAfterSearch.SUSPEND}`
        );
      })
      .catch((error) => {
        alert(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClick}
        startIcon={<ListIcon />}
      >
        Action
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            searchLine(data.msisdn);
          }}
        >
          <ListItemIcon alignItems="flex-start">
            <SearchIcon fontSize="small" />
          </ListItemIcon>
          Search
        </MenuItem>
        {data.can_refresh && (
          <MenuItem
            onClick={() => {
              refreshLine(data.msisdn);
            }}
          >
            <ListItemIcon alignItems="flex-start">
              <RefreshIcon fontSize="small" color="primary" />
            </ListItemIcon>
            Refresh
          </MenuItem>
        )}

        {data.can_restore && (
          <MenuItem
            onClick={() => {
              restoreLine(data.msisdn);
            }}
          >
            <ListItemIcon alignItems="flex-start">
              <RestoreIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            Restore
          </MenuItem>
        )}

        {data.can_suspend && (
          <MenuItem
            onClick={() => {
              suspendLine(data.msisdn);
            }}
          >
            <ListItemIcon alignItems="flex-start">
              <NotInterestedIcon fontSize="small" color="error" />
            </ListItemIcon>
            Suspend
          </MenuItem>
        )}

        {data.can_sim_swap && (
          <SimSwap msisdn={data.msisdn} setLoading={setLoading} />
        )}
      </Menu>
    </React.Fragment>
  );
};

const LinesWithDataUsage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [lines, setLines] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [rowsCount, setRowsCount] = useState();
  const exportDataLink = useRef();
  const [sortModel, setSortModel] = React.useState([
    { field: "data_used_raw", sort: "desc" },
  ]);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [searchData, setSearchData] = useState("");
  const [filterModel, setFilterModel] = useState();
  const columns = useRef([
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <LineActionComponent setLoading={setLoading} data={params.row} />
      ),
    },
    {
      field: "iccid",
      headerName: "ICCID",
      flex: 1,
      renderHeader: (params) => (
        <CopyToClipboardDataGridHeader params={params} prefix="'" />
      ),
    },
    {
      field: "msisdn",
      headerName: "MSISDN",
      flex: 1,
      minWidth: 250,
      renderHeader: (params) => (
        <CopyToClipboardDataGridHeader params={params} />
      ),
      renderCell: (params) => (
        <React.Fragment>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {params.row.msisdn && (
              <Grid item>
                <Typography>{params.row.msisdn} &nbsp;</Typography>
              </Grid>
            )}

            {params.row.has_static_ip && (
              <Grid item>
                <Chip
                  color="primary"
                  label={params.row.static_ip_version}
                ></Chip>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => <Status status={params.row.status} />,
    },
    {
      field: "data_used_raw",
      headerName: "Data Used",
      flex: 1,

      renderCell: (params) => params.row.data_used_formatted_display,
      valueGetter: (params) => {
        return params.row.data_used_raw;
      },
    },
    {
      field: "data_limit",
      headerName: "Data Limit (GB)",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "last_data_usage_updated_at",
      headerName: "Last Data Usage Updated At",
      flex: 1,
      valueGetter: (params) =>
        params.row.last_data_usage_updated_at &&
        parseInt(moment(params.row.last_data_usage_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_data_usage_updated_at && (
          <DateAndTime dateAndTime={params.row.last_data_usage_updated_at} />
        ),
    },
    {
      field: "last_line_refresh_timestamp",
      headerName: "Last Line Refreshed",
      flex: 1,
      minWidth: 220,
      valueGetter: (params) =>
        params.row.last_line_refresh_timestamp &&
        parseInt(moment(params.row.last_line_refresh_timestamp).format("x")),
      renderCell: (params) =>
        params.row.last_line_refresh_timestamp && (
          <DateAndTime dateAndTime={params.row.last_line_refresh_timestamp} />
        ),
    },
    {
      field: "billing_cycle",
      headerName: "Usage Cycle / End Date",
      flex: 1,
      valueGetter: (params) => {
        if (
          params.row.billing_cycle &&
          params.row.billing_cycle.trim() !== "" &&
          params.row.billing_cycle.trim().includes("T")
        ) {
          return moment(
            params.row.billing_cycle.trim().split("T")[0],
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        return params.row.billing_cycle;
      },
    },
  ]);

  if (isClientManger()) {
    // Access reference value:
    const newValue = columns.current;
    newValue.push({
      field: "sub_client_name",
      headerName: "Client",
      flex: 1,
      sortable: false,
    });

    // Update reference value:
    columns.current = newValue;
  } else if (isSuperAdmin() || isOrgManager()) {
    // Access reference value:
    const newValue = columns.current;
    newValue.push({
      field: "client_name",
      headerName: "Client",
      flex: 1,
      sortable: false,
    });
    newValue.push({
      field: "sub_client_name",
      headerName: "Sub Client",
      flex: 1,
      sortable: false,
    });

    // Update reference value:
    columns.current = newValue;
  }

  const exportAllData = () => {
    setLoading(true);
    axios
      .get("lines/export")
      .then(({ data }) => {
        let modifiedData = [];
        for (let lineObj of data) {
          let exporDataItem = {
            ICCID: `'${
              lineObj.iccid !== null && lineObj.iccid.trim() !== ""
                ? lineObj.iccid
                : ""
            }`,
            MSISDN: lineObj.msisdn,
            Status: lineObj.status,
            "Data Used": lineObj.data_used_formatted_display,
            "Billing Cycle": lineObj.billing_cycle,
            "Static Ip Version": lineObj.static_ip_version,
            "Last Data Usage Updated At": lineObj.last_data_usage_updated_at,
          };
          if (isClientManger()) {
            exporDataItem["Client"] = lineObj.sub_client_name;
          } else if (isSuperAdmin() || isOrgManager()) {
            exporDataItem["Client"] = lineObj.client_name;
            exporDataItem["Sub Client"] = lineObj.sub_client_name;
          }

          modifiedData.push(exporDataItem);
        }
        setExportData(modifiedData);
        setTimeout(() => {
          exportDataLink.current.link.click();
        }, 500);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = () => {
    setLoading(true);
    let params = {
      filters: filterModel,
      sort: sortModel?.[0],
    };
    if (searchData !== undefined && searchData.trim() !== "") {
      params.q = searchData;
    }
    axios
      .post(
        "lines",
        {
          ...params,
        },
        { params: { page, size: pageSize } }
      )
      .then(({ data }) => {
        setLines(data.items);
        setRowsCount(data.total);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, pageSize, searchData, filterModel, sortModel]);

  return (
    <React.Fragment>
      <Layout
        loading={loading}
        error={error}
        successMessage={successMessage}
        pageTitle="Lines With Data Usage"
        refreshPageDataFunction={fetchData}
      >
        <Box pt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={5}>
              <TextField
                id="line_id"
                label="Line ID (SIM or MSISDN)"
                variant="outlined"
                fullWidth
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
                size="small"
                minRows={5}
                multiline
              />
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="body">
                    Total Lines : {rowsCount}
                  </Typography>
                </Grid>
                <Grid item>
                  <CopyToClipboard
                    data={lines}
                    title="Copy Lines"
                    size="large"
                    properties={[
                      "iccid",
                      "msisdn",
                      "status",
                      "data_used_formatted_display",
                      "last_data_usage_updated_at",
                      "billing_cycle",
                    ]}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={exportAllData}
                  >
                    Export
                  </Button>
                  <CSVLink
                    data={exportData}
                    // headers={export_headers}
                    filename={`lines_data_usage_${moment().format(
                      "YYYYMMDD_hmmss"
                    )}.csv`}
                    ref={exportDataLink}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box pt={2}>
          <div style={{ height: "68vh", overflow: "auto" }}>
            <DataGrid
              rows={lines}
              columns={columns.current}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              onPageChange={(newPage) => setPage(newPage + 1)}
              pageSize={pageSize}
              paginationMode="server"
              rowCount={rowsCount}
              initialState={{
                pagination: {
                  page: 1,
                },
              }}
              onPageSizeChange={setPageSize}
              onFilterModelChange={(model) => {
                setFilterModel(model);
              }}
              loading={loading}
            />
          </div>
        </Box>
      </Layout>
    </React.Fragment>
  );
};

export default LinesWithDataUsage;
