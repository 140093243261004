import React from "react";
import { TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  cell: {
    fontWeight: 500,
  },
}));

const CustomizedTableCell = (props) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.cell} {...props}>
      {props.children}
    </TableCell>
  );
};

export default CustomizedTableCell;
