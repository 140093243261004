import React, { useEffect, useState, useRef } from "react";
import Layout from "../layouts/Layout";
import axios from "axios";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
} from "@material-ui/core";

import { useFormik } from "formik";

const SimSwapPage = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [formData, setFormData] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);

  const lineValidationSchema = yup.object({
    iccid: yup
      .string("iccid")
      .min(19, "Should be exactly 19 or 20 digits long")
      .max(20, "Should be exactly 19 or 20 digits long")
      .required(),
    mdn: yup
      .string("mdn")
      .test("Digits only", "The field should have 10 digits only", (value) =>
        value && value.length ? /^\d{10}$/.test(value) : true
      )
      .required(),
    imei: yup
      .string("imei")
      .test("Digits only", "The field should have 15 digits only", (value) =>
        value && value.length ? /^\d{15}$/.test(value) : true
      )
      .optional(),
  });

  const validateFunction = (data) => {
    const errors = {};
    const formatedLines = [];

    if (!data) {
      return errors;
    }

    const lines = data.split("\n");
    lines.some((line, idx) => {
      const data = line.split(",");
      if (data.length !== 2 && data.length !== 3) {
        console.log(data.length);
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      try {
        const line_data = line.split(",");
        const obtainedData =
          data.length === 3
            ? {
                mdn: line_data[0].trim(),
                iccid: line_data[1].trim(),
                imei: line_data[2].trim(),
              }
            : {
                mdn: line_data[0].trim(),
                iccid: line_data[1].trim(),
              };

        lineValidationSchema.validateSync(obtainedData);

        if (isNaN(obtainedData.iccid)) {
          throw new Error("Invalid Number");
        }

        formatedLines.push(obtainedData);
      } catch (err) {
        console.log(err);
        errors.lines = `Invalid format in line ${idx + 1}: ${line}`;
        return true;
      }

      return false;
    });

    if (!errors.lines) {
      setFormData(formatedLines);
    }

    return errors;
  };

  const updateSims = () => {
    if (formData.length === 0) {
      setError("Please enter at least 1 Sim.");
      return;
    } else {
      setLoading(true);
      axios
        .post(`/sim/swap`, formData)
        .then(({ data }) => {
          setSuccessMessage(data.message);
          history.push(`/sim/swap/${data.uuid}/status`);
        })
        .catch((error) => {
          setError(error.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const formik = useFormik({
    initialValues: {},
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      updateSims();
    },
  });

  return (
    <React.Fragment>
      <Layout
        loading={loading}
        error={error}
        pageTitle="Sim Swap"
        successMessage={successMessage}
      >
        <Box pt={4}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <InputLabel htmlFor="lines">
                  {"Add in the format"}
                  <br></br>
                  {"MDN,ICCID,[IMEI(ATT)]"}
                  <br></br>
                  {"MDN,ICCID,[IMEI(ATT)]"}
                  <br></br>
                </InputLabel>
                <FormControl
                  error={Boolean(formik.errors.lines) ? true : false}
                  sx={{ width: "100%" }}
                >
                  <TextareaAutosize
                    multiline
                    rows={6}
                    aria-label="minimum height"
                    placeholder={`Add in the format\nMDN,ICCID,[IMEI(ATT)]\nMDN,ICCID,[IMEI(ATT)]`}
                    style={{ minWidth: 550 }}
                    name="lines"
                    minRows={5}
                    id="lines"
                    //   value={rawData}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const data = e.target.value;
                      //   setRawData(data);
                      formik.setErrors(validateFunction(data));
                    }}
                  />
                  {Boolean(formik.errors.lines) ? (
                    <FormHelperText id="lines-error-text">
                      {formik.errors.lines}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  // disabled={
                  //   formik.errors.lines ||
                  //   !linesData.length ||
                  //   (iqsimCallback && formik.values.sim_group_id === undefined)
                  // }
                  // onClick={() =>
                  //   !loading && !formik.isSubmitting && formik.submitForm()
                  // }
                  size="large"
                  loading={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {"Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Layout>
    </React.Fragment>
  );
};

// const SimSwapPage = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [successMessage, setSuccessMessage] = useState(null);
//   const [searchData, setSearchData] = useState("");
//   const history = useHistory();
//   const submitSims = () => {
//     let sims = searchData.split("\n");
//     sims = sims.map((s) => s.trim());
//     sims = sims.filter(Boolean);
//     if (sims.length === 0) {
//       setError("Please enter at least 1 Sim.");
//       return;
//     } else {
//       setLoading(true);
//       axios
//         .post(`/sim-check`, sims)
//         .then(({ data }) => {
//           console.log(data);
//           setError(undefined);
//           setLoading(false);
//           history.push(`/sim-check/${data.uuid}/status`);
//         })
//         .catch((error) => {
//           setError(error.response.data.detail);
//         })
//         .finally(() => {
//           setLoading(false);
//         });
//     }
//   };

//   return (
//     <React.Fragment>
//       <Layout
//         loading={loading}
//         error={error}
//         pageTitle="Sim Check"
//         successMessage={successMessage}
//       >
//         <Box pt={2}>
//           <Grid
//             container
//             direction="row"
//             justifyContent="flex-start"
//             alignItems="center"
//             spacing={2}
//           >
//             <Grid item xs={4}>
//               <TextField
//                 id="line_id"
//                 label="Enter SIM(s) Here. Separated by New Line"
//                 variant="outlined"
//                 fullWidth
//                 value={searchData}
//                 onChange={(e) => setSearchData(e.target.value)}
//                 size="small"
//                 minRows={5}
//                 multiline
//                 maxRows={5}
//               />
//             </Grid>
//             <Grid item>
//               <Button variant="contained" color="primary" onClick={submitSims}>
//                 Submit
//               </Button>
//             </Grid>
//           </Grid>
//         </Box>
//       </Layout>
//     </React.Fragment>
//   );
// };

export default SimSwapPage;
