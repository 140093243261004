import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import moment from "moment";
import Layout from "../layouts/Layout";
import { isSuperAdmin, isClientManger, isOrgManager } from "../auth/roles";
import DateAndTime from "../components/DateAndTime";
import Status from "../components/Status";
import ArchiveIcon from "@material-ui/icons/Archive";

import SetDataLimit from "../components/SetDataLimit";
import CopyToClipboardDataGridHeader from "../components/CopyToClipboardDataGridHeader";

const AllLines = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lines, setLines] = useState([]);
  const [exportData, setExportData] = useState([]);

  const [rowsCount, setRowsCount] = useState();
  const exportDataLink = useRef();
  const [sortModel, setSortModel] = useState([
    { field: "data_used_raw", sort: "desc" },
  ]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [searchData, setSearchData] = useState("");
  const [filterModel, setFilterModel] = useState();
  const [successMessage, setSuccessMessage] = useState(null);
  const archiveLines = () => {
    axios
      .post("lines/archive", { lines: searchData })
      .then(({ data }) => {
        if (data.success) {
          setSuccessMessage(data.message);
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const columns = useRef([
    {
      field: "iccid",
      headerName: "ICCID",
      flex: 1,
      minWidth: 190,
      renderHeader: (params) => (
        <CopyToClipboardDataGridHeader params={params} prefix="'" />
      ),
    },
    {
      field: "msisdn",
      headerName: "MSISDN",
      flex: 1,
      minWidth: 180,
      renderHeader: (params) => (
        <CopyToClipboardDataGridHeader params={params} />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 140,
      renderCell: (params) => <Status status={params.row.status} />,
    },
    {
      field: "static_ip_version",
      headerName: "Static IP Version",
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return params.row.static_ip_version;
      },
      sortable: false,
    },
    {
      field: "data_used_raw",
      headerName: "Data Used",
      flex: 1,
      minWidth: 160,
      type: "number",
      align: "left",
      renderCell: (params) => params.row.data_used_formatted_display,
      valueGetter: (params) => {
        return params.row.data_used_raw;
      },
    },
    {
      field: "data_plan_limit",
      headerName: "Data Plan Limit",
      flex: 1,
      minWidth: 160,
      align: "left",
      valueGetter: (params) => {
        return params.row.data_plan_limit;
      },
    },
    {
      field: "last_data_usage_updated_at",
      headerName: "Last Data Usage Updated At",
      flex: 1,
      minWidth: 180,
      valueGetter: (params) =>
        params.row.last_data_usage_updated_at &&
        parseInt(moment(params.row.last_data_usage_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_data_usage_updated_at && (
          <DateAndTime dateAndTime={params.row.last_data_usage_updated_at} />
        ),
    },
    {
      field: "last_line_refresh_timestamp",
      headerName: "Last Line Refreshed",
      flex: 1,
      minWidth: 180,
      valueGetter: (params) =>
        params.row.last_line_refresh_timestamp &&
        parseInt(moment(params.row.last_line_refresh_timestamp).format("x")),
      renderCell: (params) =>
        params.row.last_line_refresh_timestamp && (
          <DateAndTime dateAndTime={params.row.last_line_refresh_timestamp} />
        ),
    },
    {
      field: "billing_cycle",
      headerName: "Usage Cycle / End Date",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        if (
          params.row.billing_cycle &&
          params.row.billing_cycle.trim() !== "" &&
          params.row.billing_cycle.trim().includes("T")
        ) {
          return moment(
            params.row.billing_cycle.trim().split("T")[0],
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        return params.row.billing_cycle;
      },
    },
    {
      field: "ban",
      headerName: "BAN",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "carrier",
      headerName: "Carrier",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "carrier_account",
      headerName: "Carrier Account",
      flex: 1,
      minWidth: 350,
      valueGetter: (params) => {
        return params.row.carrier_account?.email;
      },
      sortable: false,
    },
    { field: "imei", headerName: "IMEI", flex: 1, minWidth: 160 },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      flex: 1,
      minWidth: 180,
    },
    { field: "voice_used", headerName: "Voice Used", flex: 1, minWidth: 160 },
    { field: "text_sent", headerName: "Text Sent", flex: 1, minWidth: 160 },
    {
      field: "product_type",
      headerName: "Product Type",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "tmo_service_name",
      headerName: "TMO Service Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "tmo_service_effective_date",
      headerName: "TMO Service Effective Date",
      flex: 1,
      minWidth: 200,
    },
    { field: "att_uid", headerName: "ATT UID", flex: 1, minWidth: 150 },
    {
      field: "rate_plan_name",
      headerName: "Rate Plan Name",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "has_disable_filtering_subscriber_level",
      headerName: "Filtering Disabled",
      flex: 1,
      minWidth: 300,
      type: "boolean",
      renderCell: (params) => {
        return params.row.has_disable_filtering_subscriber_level ? "Yes" : "";
      },
      sortable: false,
    },
    {
      field: "rate_plan_price",
      headerName: "Rate Plan Price",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "is_archive",
      headerName: "Is Archived?",
      minWidth: 150,
      type: "boolean",
      renderCell: (params) => {
        return params.row.is_archive ? "Yes" : "";
      },
      sortable: false,
    },
    {
      field: "data_limit",
      headerName: "Data Limit (GB)",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "tmo_blk_intl_data",
      headerName: "Block International Data",
      minWidth: 150,
      type: "boolean",
      renderCell: (params) => {
        return params.row.tmo_blk_intl_data ? "Yes" : "";
      },
      sortable: false,
    },
  ]);

  if (isClientManger()) {
    // Access reference value:
    const newValue = columns.current;
    newValue.push({
      field: "sub_client_name",
      headerName: "Client",
      flex: 1,
      minWidth: 200,
      sortable: false,
    });

    // Update reference value:
    columns.current = newValue;
  } else if (isSuperAdmin() || isOrgManager()) {
    // Access reference value:
    const newValue = columns.current;
    newValue.push({
      field: "client_name",
      headerName: "Client",
      flex: 1,
      minWidth: 200,
      sortable: false,
    });
    newValue.push({
      field: "sub_client_name",
      headerName: "Sub Client",
      flex: 1,
      minWidth: 200,
      sortable: false,
    });
    // Update reference value:
    columns.current = newValue;
  }

  const getExportableData = (data) => {
    let modifiedData = [];
    for (let lineObj of data) {
      let exporDataItem = {
        ICCID: `'${
          lineObj.iccid !== null && lineObj.iccid.trim() !== ""
            ? lineObj.iccid
            : ""
        }`,
        MSISDN: lineObj.msisdn,
        Status: lineObj.status,
        "Static Ip Version": lineObj.static_ip_version,
        "Data Used": lineObj.data_used_formatted_display,
        "Billing Cycle": lineObj.billing_cycle,
        Carrier: lineObj.carrier,
        "Carrier Account": lineObj.carrier_account?.email,
        IMEI: lineObj.imei,
        Manufacturer: lineObj.manufacturer?.replace(/"/g, '""'),
        "Voice Used": lineObj.voice_used,
        "Text Sent": lineObj.text_sent,
        "Product Type": lineObj.product_type,
        "TMO Service Name": lineObj.tmo_service_name,
        "TMO Service Effefctive Date": lineObj.tmo_service_effective_date,
        "ATT UID": lineObj.att_uid,
        "Rate Plan Name": lineObj.rate_plan_name,
        "Filtering Disabled": lineObj.has_disable_filtering_subscriber_level
          ? "Yes"
          : "",
        "Rate Plan Price": lineObj.rate_plan_price,
        "Is Archieved": lineObj.is_archive ? "Yes" : "",
        "Data Limit (GB)": lineObj.data_limit,
        "Last Data Usage Updated At": lineObj.last_data_usage_updated_at,
        "Block International Data": lineObj.tmo_blk_intl_data ? "Yes" : "",
      };

      if (isClientManger()) {
        exporDataItem["Client"] = lineObj.sub_client_name;
        exporDataItem["Tag"] = lineObj.tag?.name;
      } else if (isSuperAdmin() || isOrgManager()) {
        exporDataItem["Client"] = lineObj.client_name;
        exporDataItem["Sub Client"] = lineObj.sub_client_name;
        exporDataItem["Tag"] = lineObj.tag?.name;
      }

      modifiedData.push(exporDataItem);
    }
    return modifiedData;
  };

  const exportAllData = () => {
    setLoading(true);
    axios
      .post("lines/all/export", {
        ...getParams()
      })
      .then(({ data }) => {
        setExportData(getExportableData(data));
        setTimeout(() => {
          exportDataLink.current.link.click();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getParams = () => {
    let params = { filters: filterModel, sort: sortModel?.[0] };
    if (searchData !== undefined && searchData.trim() !== "") {
      params.q = searchData;
    }
    return params;
  };

  const fetchData = ()=>{
     setLoading(true);
     axios
       .post(
         "lines/all",
         {
           ...getParams(),
         },
         { params: { page, size: pageSize }}
       )
       .then(({ data }) => {
         setLines(data.items);
         setRowsCount(data.total);
       })
       .catch((error) => {
         setError(error.response.data.detail);
       })
       .finally(() => {
         setLoading(false);
       });
  }
  useEffect(() => {
   fetchData();
    // eslint-disable-next-line
  }, [page, filterModel, pageSize, searchData, sortModel]);

  const setDataLimit = (dataLimitValue) => {
    axios
      .post(`/lines/set-data-limit`, {
        limit: dataLimitValue,
        lines: searchData,
      })
      .then(({ data }) => {
        if (data.success) {
          setSuccessMessage(data.message);
          fetchData();
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Layout
        loading={loading}
        error={error}
        pageTitle="All Lines"
        successMessage={successMessage}
        refreshPageDataFunction={fetchData}
      >
        <Box pt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={2}>
              <TextField
                id="line_id"
                label="Line ID (SIM or MSISDN)"
                variant="outlined"
                fullWidth
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
                size="small"
                minRows={5}
                multiline
                maxRows={5}
              />
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                {searchData && searchData.trim() !== "" && lines.length !== 0 && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<ArchiveIcon />}
                      onClick={archiveLines}
                    >
                      Archive Lines
                    </Button>
                  </Grid>
                )}

                {searchData && searchData.trim() !== "" && lines.length !== 0 && (
                  <Grid item>
                    <SetDataLimit callOnBtnClick={setDataLimit} />
                  </Grid>
                )}

                <Grid item>
                  <Typography variant="body">
                    Total Lines : {rowsCount}{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={exportAllData}
                  >
                    Export
                  </Button>
                  <CSVLink
                    data={exportData}
                    // headers={export_headers}
                    filename={`all_lines_${moment().format(
                      "YYYYMMDD_hmmss"
                    )}.csv`}
                    ref={exportDataLink}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box pt={2}>
          <div style={{ height: "68vh", overflow: "auto" }}>
            <DataGrid
              rows={lines}
              columns={columns.current}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              onPageChange={(newPage) => setPage(newPage + 1)}
              pageSize={pageSize}
              paginationMode="server"
              rowCount={rowsCount}
              initialState={{
                pagination: {
                  page: 1,
                },
              }}
              onPageSizeChange={setPageSize}
              filterMode="server"
              onFilterModelChange={(model) => {
                setFilterModel(model);
              }}
            />
          </div>
        </Box>
      </Layout>
    </React.Fragment>
  );
};

export default AllLines;
