import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Box, Grid, Link, Tooltip, Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import Layout from "../layouts/Layout";
import DateAndTime from "../components/DateAndTime";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& .past-due": {
        backgroundColor: red[200],
        "&:hover": {
          backgroundColor: red[400],
        },
      },
    },
  };
});

const BillingData = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [billingData, setBillingData] = useState([]);
  const [sortModel, setSortModel] = useState([
    { field: "last_updated_at", sort: "desc" },
  ]);
  const [rowsCount, setRowsCount] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const exportDataLink = useRef();
  const [exportableData, setExportableData] = useState([]);

  const columns = useRef([
    {
      field: "carrier_account.email",
      headerName: "Carrier Email",
      flex: 1,
      minWidth: 300,
      valueGetter: (params) => params.row.carrier_account.email,
      renderCell: (params) => (
        <Tooltip title="View Billing Data ">
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              history.push(
                `/carrier-account/${params.row.carrier_account.uuid}/billing-data`
              );
            }}
          >
            {params.row.carrier_account.email}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: "carrier_account.carrier",
      headerName: "Carrier",
      flex: 1,
      valueGetter: (params) => params.row.carrier_account.carrier,
    },
    {
      field: "ban",
      headerName: "BAN",
      flex: 1,
    },
    {
      field: "auto_pay_status",
      headerName: "Auto Pay Status",
      flex: 1,
    },
    { field: "bill_amount", headerName: "Bill Amount", flex: 1 },
    { field: "bill_due_amount", headerName: "Bill Due Amount", flex: 1 },
    {
      field: "bill_cycle_start_date",
      headerName: "Bill Start Date",
      flex: 1,
      renderCell: (params) =>
        params.row.bill_cycle_start_date &&
        params.row.bill_cycle_start_date.split("T")[0],
    },
    {
      field: "bill_cycle_end_date",
      headerName: "Bill End Date",
      flex: 1,
      renderCell: (params) =>
        params.row.bill_cycle_end_date &&
        params.row.bill_cycle_end_date.split("T")[0],
    },
    {
      field: "bill_cycle_due_date",
      headerName: "Bill Due Date",
      flex: 1,
      renderCell: (params) =>
        params.row.bill_cycle_due_date &&
        params.row.bill_cycle_due_date.split("T")[0],
    },
    {
      field: "bill_media",
      headerName: "Bill Type",
      flex: 1,
    },
    {
      field: "last_updated_at",
      headerName: "Last Time Data Fetched",
      flex: 1,
      valueGetter: (params) =>
        params.row.last_updated_at &&
        parseInt(moment(params.row.last_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_updated_at && (
          <DateAndTime dateAndTime={params.row.last_updated_at} />
        ),
    },
  ]);

  const fetchBillingData = () => {
    setLoading(true);
    axios
      .get(`/carrier-accounts/billing-data`, {
        params: {
          is_active: true,
          page,
          size: pageSize,
        },
      })
      .then(({ data }) => {
        setBillingData(data.items);
        setRowsCount(data.total);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportData = () => {
    let exportableDataList = [];
    for (let exportDataObj of billingData) {
      let dataToPush = {
        id: exportDataObj.id,
        carrier_account_email: exportDataObj.carrier_account.email,
        carrier: exportDataObj.carrier_account.carrier,
        ...exportDataObj,
      };
      delete dataToPush["carrier_account"];
      exportableDataList.push(dataToPush);
    }
    setExportableData(exportableDataList);
    setTimeout(() => {
      exportDataLink.current.link.click();
    }, 500);
  };

  useEffect(() => {
    fetchBillingData();
  }, [page, pageSize]);

  return (
    <Layout
      loading={loading}
      error={error}
      pageTitle="Billing Data"
      refreshPageDataFunction={fetchBillingData}
    >
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={exportData}
          >
            Export
          </Button>
          <CSVLink
            data={exportableData}
            // headers={export_headers}
            filename={`billing_data_${moment().format("YYYYMMDD_hmmss")}.csv`}
            ref={exportDataLink}
          />
        </Grid>
      </Grid>
      <Box pt={2} className={classes.root}>
        <DataGrid
          rows={billingData}
          columns={columns.current}
          disableSelectionOnClick
          autoHeight
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onPageChange={(newPage) => setPage(newPage + 1)}
          pageSize={pageSize}
          paginationMode="server"
          rowCount={rowsCount}
          initialState={{
            pagination: {
              page: 1,
            },
          }}
          onPageSizeChange={setPageSize}
          loading={loading}
          getRowClassName={(params) =>
            params.getValue(params.id, "is_past_due") ? "past-due" : ""
          }
        />
      </Box>
    </Layout>
  );
};
export default BillingData;
