import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Typography, Box, Grid, IconButton, Tooltip, Button, TextField } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import Layout from "../layouts/Layout";
import { useHistory } from "react-router-dom";
import { red, blue } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import GroupIcon from "@material-ui/icons/Group";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import AddOrUpdateClient from "./AddOrUpdateClient";
import DateAndTime from "../components/DateAndTime";


const Clients = () => {
  let history = useHistory();

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [sortModel, setSortModel] = React.useState();
  const [rowsCount, setRowsCount] = useState();
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [searchData, setSearchData] = useState("");

    const getParams = () => {
      let params = { page,size:pageSize };
      if (searchData !== undefined && searchData.trim() !== "") {
        params.q = searchData;
      }
      return params;
    };

  const fetchClients = () => {
    setLoading(true);
    axios
      .get("/clients", {
        params: getParams(),
      })
      .then(({ data }) => {
        setClients(data.items);
        setRowsCount(data.total);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line
  }, [page, searchData]);

  const deleteClient = (client_id) => {
    setLoading(true);
    axios
      .delete(`/client/${client_id}`)
      .then(({ data }) => {
        if (data.success) {
          setSuccessMessage("Client Deleted Successfully.");
          fetchClients();
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useRef([
    {
      field: "name",
      headerName: "Name",
      flex: 1,

      renderCell: (params) => (
        <Grid container justifyContent="space-between">
          <Grid item>
            {params.row.name}
            {params.row.parent_client && (
              <React.Fragment>({params.row.parent_client.name})</React.Fragment>
            )}
          </Grid>
          <Grid item>
            <AddOrUpdateClient
              setLoading={setLoading}
              refreshClients={fetchClients}
              clientData={params.row}
              mode="edit"
            />
          </Grid>
        </Grid>
      ),
    },
    { field: "total_lines", headerName: "No Of Lines", flex: 1 },
    {
      field: "last_updated_at",
      headerName: "Last Updated At",
      flex: 1,
      renderCell: (params) => (
        <DateAndTime dateAndTime={params.row.last_updated_at} />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <React.Fragment>
          <Grid container justifyContent="flex-start" spacing={3}>
            <Grid item>
              <Tooltip title="Assign/Unassign Lines">
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    history.push(`/client/${params.row.uuid}/manage-lines`);
                  }}
                >
                  <SwapHorizontalCircleIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Client Users">
                <IconButton
                  style={{
                    color: blue[500],
                  }}
                  onClick={() => {
                    history.push(`/client/${params.row.uuid}/users`);
                  }}
                >
                  <GroupIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Delete Client">
                <IconButton
                  style={{
                    color: red[500],
                  }}
                  onClick={() => {
                    deleteClient(params.row.uuid);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </React.Fragment>
      ),
    },
  ]);

  return (
    <Layout loading={loading} error={error} successMessage={successMessage}>
      <Box mb={2} pt={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" align="left">
              Clients
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="line_id"
              label="Search"
              variant="outlined"
              fullWidth
              value={searchData}
              onChange={(e) => setSearchData(e.target.value)}
              size="small"
            
            />
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    history.push(`/unassigned-lines`);
                  }}
                >
                  Show UnAssigned Lines
                </Button>
              </Grid>
              <Grid item>
                <AddOrUpdateClient
                  setLoading={setLoading}
                  refreshClients={fetchClients}
                  mode="new"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <div style={{ height: "80vh", overflow: "auto" }}>
          <DataGrid
            rows={clients}
            columns={columns.current}
            disableSelectionOnClick
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onPageChange={(newPage) => setPage(newPage + 1)}
            pageSize={pageSize}
            paginationMode="server"
            rowCount={rowsCount}
            initialState={{
              pagination: {
                page: 1,
              },
            }}
            getRowId={(row) => row.uuid}
            onPageSizeChange={setPageSize}
          />
        </div>
      </Box>
    </Layout>
  );
};

export default Clients;
