import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Grid,
  Chip,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import Layout from "../layouts/Layout";
import { useParams } from "react-router-dom";
import AddUser from "./AddUser";
import queryString from "query-string";
import DateAndTime from "../components/DateAndTime";
import EditClientUserDetails from "../components/EditClientUserDetails";

const ManageClientUsers = (props) => {
  const { client } = queryString.parse(props.location.search);
  const { client_uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [clientName, setClientName] = useState();
  const [users, setUsers] = useState([]);
  const [sortModel, setSortModel] = React.useState();
  const [rowsCount, setRowsCount] = useState();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);

  const columns = useRef([
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 1,
      renderCell: (params) => (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Typography>{params.row.full_name} &nbsp; </Typography>
              </Grid>

              {params.row.is_client_manager && (
                <Grid item>
                  <Chip color="primary" label="Client Manager"></Chip>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <EditClientUserDetails
              userInfo={params.row}
              setLoading={setLoading}
              callOnSuccess={fetchClientUsers}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "last_updated_at",
      headerName: "Last Updated At",
      flex: 1,
      renderCell: (params) => (
        <DateAndTime dateAndTime={params.row.last_updated_at} />
      ),
    },
  ]);

  const fetchClientDetails = () => {
    setLoading(true);
    axios
      .get(`/client/${client_uuid}`)
      .then(({ data }) => {
        setClientName(data.name);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchClientUsers = () => {
    setLoading(true);
    axios
      .get(`/client/${client_uuid}/users`, {
        params: { page, size: pageSize },
      })
      .then(({ data }) => {
        setUsers(data.items);
        setRowsCount(data.total);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchClientDetails();
    fetchClientUsers();
    // eslint-disable-next-line
  }, [client_uuid]);

  return (
    <Layout loading={loading} error={error}>
      <Box mb={2} pt={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" align="left">
              Manage Users {!client && `for ${clientName}`}
            </Typography>
          </Grid>

          <Grid item>
            <AddUser refreshUsers={fetchClientUsers} />
          </Grid>
        </Grid>
      </Box>

      {users.length === 0 && (
        <Box pb={2}>
          <Typography variant="h6" align="center">
            {" "}
            No Users.
          </Typography>
        </Box>
      )}

      {users.length !== 0 && (
        <Box pt={2}>
          <DataGrid
            rows={users}
            columns={columns.current}
            disableSelectionOnClick
            autoHeight
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onPageChange={(newPage) => setPage(newPage + 1)}
            pageSize={pageSize}
            paginationMode="server"
            rowCount={rowsCount}
            initialState={{
              pagination: {
                page: 1,
              },
            }}
            getRowId={(row) => row.uuid}
            onPageSizeChange={setPageSize}
          />
        </Box>
      )}
    </Layout>
  );
};
export default ManageClientUsers;
