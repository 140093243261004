import React from "react";
import PropTypes from "prop-types";
import CopyToClipboard from "./CopyToClipboard";
import { Typography } from "@material-ui/core";

const CopyToClipboardDataGridHeader = (props) => {
  const { params,prefix } = props;

  return (
    <React.Fragment>
        <Typography variant="body" style={{fontWeight:500}}>
          {params.colDef.headerName}
        </Typography>
        <CopyToClipboard
          data={params.api.state.sorting.sortedRows.map(
            (key) => params.api.state.rows.idRowsLookup[key]
          )}
          properties={[params.colDef.field]}
          title={`Copy ${params.colDef.headerName}`}
          prefix={prefix}
        />
     
    </React.Fragment>
  );
};

CopyToClipboard.propTypes = {
  params: PropTypes.object,
  prefix: PropTypes.string,
};

CopyToClipboard.defaultProps = {
  params: {},
  prefix: "",
};

export default CopyToClipboardDataGridHeader;
