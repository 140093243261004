import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  Grid,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
} from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

export default function AddOrUpdateCarrierAccount(props) {
  const { refreshCarrierAccounts, carrierAccountData, mode, setLoading } =
    props;
  const [open, setOpen] = useState(false);

  const [message, setMessage] = useState();

  //Carrier Acconunt Creation Fields
  const [email, setEmail] = useState(carrierAccountData?.email);
  const [password, setPassword] = useState(carrierAccountData?.password);
  const [carrier, setCarrier] = useState(
    carrierAccountData ? carrierAccountData.carrier : "TMO"
  );
  const [otpEmail, setOtpEmail] = useState(carrierAccountData?.otp_email);
  const [otpPhoneNumber, setOtpPhoneNumber] = useState(
    carrierAccountData?.otp_phone_number
  );
  const [proxy, setProxy] = useState(carrierAccountData?.proxy);
  const [secondaryProxy, setSecondaryProxy] = useState(
    carrierAccountData?.secondary_proxy
  );
  const [username, setUsername] = useState(carrierAccountData?.username);
  const [scrapeDataUsage, setScrapeDataUsage] = useState(
    carrierAccountData ? carrierAccountData.scrape_data_usage : true
  );
  const [uids, setUids] = useState(
    carrierAccountData?.uids ? carrierAccountData.uids.join(",") : ""
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const createCarrierAccount = () => {
    setLoading(true);
    let errorMessages = [];

    if (email === undefined || email.trim() === "") {
      errorMessages.push("Enter Email");
    }

    if (password === undefined || password.trim() === "") {
      errorMessages.push("Enter Password");
    }

    if (errorMessages.length !== 0) {
      console.log(errorMessages.join("\r\n"));
      setMessage({ type: "error", text: errorMessages.join("</br>") });
      setLoading(false);
      return;
    }

    const accountToCreateOrUpdate = {
      email,
      password,
      carrier,
      proxy,
      otp_email: otpEmail,
      otp_phone_number: otpPhoneNumber,
      secondary_proxy: secondaryProxy,
      username,
      scrape_data_usage: scrapeDataUsage,
      uids: uids !== undefined && uids.trim() !== "" ? uids.split(",") : [],
    };

    if (mode === "edit") {
      axios
        .put(
          `/carrier-account/${carrierAccountData.uuid}`,
          accountToCreateOrUpdate
        )
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            setMessage({
              type: "success",
              text: "Carrier Account Updated Successfully.",
            });
          }
        })
        .catch((error) => {
          setMessage({ type: "error", text: error.response.data.detail });
        })
        .finally(() => {
          setLoading(false);
          refreshCarrierAccounts();
        });
    } else {
      axios
        .post(`/carrier-account`, accountToCreateOrUpdate)
        .then((response) => {
          if (response.status === 200) {
            setMessage({
              type: "success",
              text: "Carrier Account Created Successfully.",
            });
            refreshCarrierAccounts();
          }
        })
        .catch((error) => {
          setMessage({ type: "error", text: error.response.data.detail });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <React.Fragment>
      {mode === "edit" && (
        <Tooltip title="Edit Account">
          <IconButton aria-label="delete" onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {mode === "new" && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
        >
          Add New Carrier Account
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {mode === "edit" ? "Update" : "Add New"} Carrier Account
        </DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}>
                <Typography
                  variant="body"
                  dangerouslySetInnerHTML={{ __html: message.text }}
                ></Typography>{" "}
              </Alert>
            </Box>
          )}
          <Box pb={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel>Carrier</InputLabel>
                  <Select
                    value={carrier}
                    onChange={(e) => {
                      setCarrier(e.target.value);
                    }}
                  >
                    <MenuItem value="TMO">T-Mobile</MenuItem>
                    <MenuItem value="ATT">AT&T</MenuItem>
                    <MenuItem value="VZN">Verizon</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter User Email"
                  type="email"
                  required
                  value={email}
                />
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                  required
                  value={password}
                />
              </Grid>
              {carrier === "ATT" && (
                <Grid item>
                  <Tooltip title="Enter UIDs for ATT account(Separated by comma(,)).">
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Enter UIDs(ATT)"
                      onChange={(e) => setUids(e.target.value)}
                      placeholder="Enter UIDs(ATT)"
                      value={uids}
                    />
                  </Tooltip>
                </Grid>
              )}

              <Grid item>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Proxy"
                  onChange={(e) => setProxy(e.target.value)}
                  placeholder="Enter Proxy"
                  value={proxy}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Secondary Proxy"
                  onChange={(e) => setSecondaryProxy(e.target.value)}
                  placeholder="Enter Secondary Proxy"
                  value={secondaryProxy}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Enter OTP Email if different than the primary email.">
                  <TextField
                    fullWidth
                    margin="dense"
                    label="OTP Email (If different than primary email)"
                    onChange={(e) => setOtpEmail(e.target.value)}
                    placeholder="Enter Email for OTP (Optional)"
                    type="email"
                    value={otpEmail}
                  />
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Enter if One Time Password receiving on phone number.">
                  <TextField
                    fullWidth
                    margin="dense"
                    label="OTP Phone Number"
                    onChange={(e) => setOtpPhoneNumber(e.target.value)}
                    placeholder="Enter OTP Phone Number"
                    value={otpPhoneNumber}
                  />
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Enter username if username is used for login instead of primary email.">
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter Username"
                    value={username}
                  />
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Enable or Disable the Data Usage Scraping">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={scrapeDataUsage}
                        onChange={(e) => setScrapeDataUsage(e.target.checked)}
                      />
                    }
                    label="Scrape Data Usage"
                    value={scrapeDataUsage}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={createCarrierAccount}
            color="primary"
            // disabled={loading}
          >
            {mode === "edit" ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

AddOrUpdateCarrierAccount.propTypes = {
  refreshCarrierAccounts: PropTypes.any.isRequired,
};
