import React, { useEffect, useState, useRef } from "react";
import Layout from "../layouts/Layout";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";

const SimCheck = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [type, setType] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [searchData, setSearchData] = useState("");
  const history = useHistory();
  const [formError, setFormError] = useState(null);
  const submitSims = (carrierType) => {
    let sims = searchData.split("\n");
    sims = sims.map((s) => s.trim());
    sims = sims.filter(Boolean);
    if (sims.length === 0) {
      setError("Please enter at least 1 Sim.");
      return;
    } else {
      setLoading(true);
      axios
        .post(
          `/sim/check/${
            carrierType === "att"
              ? "att"
              : carrierType === "tmo"
              ? "tmo"
              : "vzn"
          }`,
          sims
        )
        .then(({ data }) => {
          console.log(data);
          setError(undefined);
          setLoading(false);
          history.push(`/sim-check/${data.uuid}/status`);
        })
        .catch((error) => {
          setError(error.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setFormError(null);
    if (!type) return;
    let sims = searchData.split("\n");
    sims = sims.map((s) => s.trim());
    sims = sims.filter(Boolean);
    if (sims.length === 0) {
      return;
    }

    sims.map((val) => {
      if (type === "att" && val.length !== 20) {
        setFormError("ATT iccid should be 20 digits long");
        return;
      } else if (type === "tmo" && val.length !== 19) {
        setFormError("TMO iccid should be 19 digits long");
        return;
      } else if (type === "vzn" && val.length !== 20) {
        setFormError("VZN iccid should be 20 digits long");
        return;
      }
    });
  }, [type, searchData]);

  return (
    <React.Fragment>
      <Layout
        loading={loading}
        error={error}
        pageTitle="Sim Check"
        successMessage={successMessage}
      >
        <RadioGroup
          row
          name="row-radio-buttons-group"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <FormControlLabel value="tmo" control={<Radio />} label="TMO" />
          <FormControlLabel value="att" control={<Radio />} label="ATT" />
          <FormControlLabel value="vzn" control={<Radio />} label="VZN" />
        </RadioGroup>
        <Box pt={2}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <TextField
                id="line_id"
                label="Enter SIM(s) Here. Separated by New Line"
                variant="outlined"
                fullWidth
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
                size="small"
                minRows={5}
                multiline
                maxRows={5}
                error={formError}
                helperText={formError || ""}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => submitSims(type)}
                disabled={!type}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Layout>
    </React.Fragment>
  );
};

export default SimCheck;
