import React, { useState, useEffect, useRef, createRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../layouts/Layout";
import axios from "axios";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Link,
} from "@material-ui/core";
import Status from "../components/Status";

const BillingDataStatus = (props) => {
  const { billing_data_uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [billingData, setBillingData] = useState();
  const [billingRecords,setBillingRecords] = useState([]);
  const refreshInterval = useRef();
  const history = useHistory();

  const fetchStatus = () => {
    setLoading(true);
    axios
      .get(`/account-billing/${billing_data_uuid}/status`)
      .then(({ data }) => {
        console.log(data);
        setBillingData(data);
        if (!["In Progress", "In Queue"].includes(data.status)) {
          clearInterval(refreshInterval.current);
        }
        if (Array.isArray(JSON.parse(data.response))) {
          setBillingRecords(JSON.parse(data.response));
        }
      })
      .catch((error) => {
        clearInterval(refreshInterval.current);
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStatus();
    refreshInterval.current = setInterval(() => {
      fetchStatus();
    }, 5000);
    return () => clearInterval(refreshInterval.current);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      containerMaxWidth="md"
      loading={loading}
      error={error}
      successMessage={successMessage}
      pageTitle="Fetching Billing Data"
      refreshPageDataFunction={fetchStatus}
    >
      {billingData && (
        <Box mb={2} pt={2}>
          <TableContainer component={Paper}>
            <Table size="small" style={{ tableLayout: "fixed" }}>
              <TableBody>
                <TableRow>
                  <TableCell>Carrier Email Id </TableCell>

                  <TableCell>
                    <Tooltip title="View Billing Data ">
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          history.push(
                            `/carrier-account/${billingData.carrier_account.uuid}/billing-data`
                          );
                        }}
                      >
                        {billingData.carrier_account.email}
                      </Link>
                    </Tooltip>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Carrier </TableCell>
                  <TableCell>{billingData.carrier_account.carrier}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Status </TableCell>
                  <TableCell>
                    <Status status={billingData.status} />
                  </TableCell>
                </TableRow>

                {billingData.status && billingData.status === "Failed" && (
                  <TableRow>
                    <TableCell>Error </TableCell>
                    <TableCell> {billingData.response} </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {billingRecords.map((data) => (
        <React.Fragment>
          <Box pt={2} pb={2}>
            <Typography variant="h5">
              Billing data for BAN - {data.ban}
            </Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table size="small" style={{ tableLayout: "fixed" }}>
              <TableBody>
                <TableRow>
                  <TableCell>Auto Pay Status </TableCell>
                  <TableCell>{data.auto_pay_status}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Bill Amount</TableCell>
                  <TableCell>{data.bill_amount}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Bill Due Amount</TableCell>
                  <TableCell>{data.bill_due_amount}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Bill Start Date</TableCell>
                  <TableCell>
                    {data.bill_cycle_start_date &&
                      data.bill_cycle_start_date.split("T")[0]}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Bill End Date</TableCell>
                  <TableCell>
                    {data.bill_cycle_end_date &&
                      data.bill_cycle_end_date.split("T")[0]}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Bill Due Date</TableCell>
                  <TableCell>
                    {data.bill_cycle_due_date &&
                      data.bill_cycle_due_date.split("T")[0]}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Bill Media</TableCell>
                  <TableCell>{data.bill_media}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Past Due</TableCell>
                  <TableCell>
                    {data?.is_over_due ||
                    (data.bill_due_amount !== "N/A" &&
                      data.bill_cycle_due_date !== "N/A" &&
                      parseFloat(data.bill_due_amount) > 0 &&
                      data?.auto_pay_status == "opt-out" &&
                      Date.now() > Date.parse(data.bill_cycle_due_date))
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ))}
    </Layout>
  );
};

export default BillingDataStatus;
