import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

const AddOrUpdateClient = (props) => {
  const { refreshClients, clientData, mode, setLoading } = props;

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [clientName, setClientName] = useState(clientData?.name);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const createClient = () => {
    setLoading(true);
    if (mode === "edit") {
      axios
        .put(`/client/${clientData.uuid}`, { name: clientName })
        .then((response) => {
          if (response.status === 200) {
            setMessage({
              type: "success",
              text: "Client Updated Successfully.",
            });
            refreshClients();
          }
        })
        .catch((error) => {
          setMessage({
            type: "error",
            text: error.response.data.detail,
          });
        })
        .finally(() => {
          setLoading(false);
          refreshClients();
        });
    } else {
      axios
        .post(`/client`, { name: clientName })
        .then((response) => {
          if (response.status === 200) {
            setMessage({
              type: "success",
              text: "Client Created Successfully.",
            });
            refreshClients();
          }
        })
        .catch((error) => {
          setMessage({ type: "error", text: error.response.data.detail });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <React.Fragment>
      {mode === "edit" && (
        <Tooltip title="Update Client">
          <IconButton onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {mode === "new" && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
        >
          Add New Client
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Add New Client</DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}
          <Box pb={3}>
            <TextField
              fullWidth
              autoFocus
              margin="dense"
              id="name"
              label="Client Name"
              onChange={(e) => setClientName(e.target.value)}
              placeholder="Enter Client Name"
              value={clientName}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={createClient} color="primary">
            {mode === "edit" ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

AddOrUpdateClient.propTypes = {
  refreshClients: PropTypes.any.isRequired,
};

export default AddOrUpdateClient;
