import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Button,
  Grid,
  Chip,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import Layout from "../layouts/Layout";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateAndTime from "../components/DateAndTime";
import Status from "../components/Status";

const CarrierAccountAlerts = () => {
  const { carrier_account_uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  
  const [carrierAccountData, setCarrierAccountData] = useState();
  const [clientLines, setClientLines] = useState([]);
  const [sortModel, setSortModel] = React.useState([
    { field: "last_updated_at", sort: "desc" },
  ]);
  const [rowsCount, setRowsCount] = useState();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const columns = useRef([
    { field: "sent_date", headerName: "Alert Sent Date", flex: 1 },
    {
      field: "line_id",
      headerName: "MSISDN",
      flex: 1,
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
    },
    {
      field: "ban",
      headerName: "BAN",
      flex: 1,
    },
    {
      field: "bill_cycle_end_date",
      headerName: "Billing Cycle End Date",
      flex: 1,
    },
    {
      field: "last_updated_at",
      headerName: "Created At",
      flex: 1,
      valueGetter: (params) =>
        params.row.last_updated_at &&
        parseInt(moment(params.row.last_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_updated_at && (
          <DateAndTime dateAndTime={params.row.last_updated_at} />
        ),
    },
  ]);

  const fetchCarrierDetails = () => {
    setLoading(true);
    axios
      .get(`/carrier-account/${carrier_account_uuid}`)
      .then(({ data }) => {
        setCarrierAccountData(data);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCarrierAccoutntAlerts = () => {
    setLoading(true);
    axios
      .get(`/carrier-account/${carrier_account_uuid}/alerts`, {
        params: {
          page,
          size: pageSize,
        },
      })
      .then(({ data }) => {
        setClientLines(data.items);
        setRowsCount(data.total);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCarrierDetails();
    // eslint-disable-next-line
  }, [carrier_account_uuid]);

  useEffect(() => {
    fetchCarrierAccoutntAlerts();
    // eslint-disable-next-line
  }, [page,pageSize]);

  return (
    <Layout loading={loading} error={error}>
      <Box mb={2} pt={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" align="left">
              Alerts for {carrierAccountData?.email} (
              {carrierAccountData?.carrier})
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
       
        <DataGrid
          rows={clientLines}
          columns={columns.current}
          disableSelectionOnClick
          autoHeight
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onPageChange={(newPage) => setPage(newPage + 1)}
          pageSize={pageSize}
          paginationMode="server"
          rowCount={rowsCount}
          initialState={{
            pagination: {
              page: 1,
            },
          }}
          onPageSizeChange={setPageSize}
          filterMode="server"
        
          loading={loading}
        />
      </Box>
    </Layout>
  );
};
export default CarrierAccountAlerts;
