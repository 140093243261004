
exports.isSuperAdmin = ()=>{
    return JSON.parse(localStorage.getItem("userData"))?.is_super_admin === true;
    
}

exports.isClientManger = ()=>{
    return JSON.parse(localStorage.getItem("userData"))?.is_client_manager === true;
    
}

exports.isOrgManager = ()=>{
    return  JSON.parse(localStorage.getItem("userData"))?.is_org_manager === true;
    
}