import React, { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Checkbox,
  DialogContentText,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import DataUsageIcon from "@material-ui/icons/DataUsage";

const SetDataLimit = (props) => {
  const { callOnBtnClick, callOnSuccess } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [dataLimitValue,setDataLimitValue] = useState(750);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<DataUsageIcon />}
        onClick={() => {
          setOpen(true);
        }}
      >
        Set Data Limit
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Set Data Limit</DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}

          <DialogContentText>Set the data limit in GB for the filtered lines.</DialogContentText>
          <Box pb={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  type="number"
                  id="data_limit"
                  label="Data Limit"
                  onChange={(e) => setDataLimitValue(e.target.value)}
                  placeholder="Enter Data Limit"
                  value={dataLimitValue}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={()=>{
            callOnBtnClick(dataLimitValue);
            setOpen(false);
          }} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

SetDataLimit.propTypes = {
  callOnBtnClick: PropTypes.func.isRequired,
  callOnSuccess: PropTypes.func.isRequired,
};

export default SetDataLimit;
