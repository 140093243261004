import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Typography, Box, Button, Grid, TextField,Chip } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import Layout from "../layouts/Layout";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateAndTime from "../components/DateAndTime";
import Status from "../components/Status";

const ManageClientLines = () => {
    const { client_uuid } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [clientName, setClientName] = useState();
    const [clientLines, setClientLines] = useState([]);
    const [filterModel, setFilterModel] = useState();
    const [rowsCount, setRowsCount] = useState();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [linesToUpdate, setLinesToUpdate] = useState();
    const [exportData, setExportData] = useState([]);
    const exportDataLink = useRef();
    const [sortModel, setSortModel] = useState([
        { field: "last_data_usage_updated_at", sort: "desc" },
      ]);

    const columns = useRef([
      { field: "iccid", headerName: "ICCID", flex: 1 },
      {
        field: "msisdn",
        headerName: "MSISDN",
        flex: 1,
        renderCell: (params) => (
          <React.Fragment>
            {params.row.msisdn && (
              <Typography>{params.row.msisdn} &nbsp;</Typography>
            )}
            {params.row.has_static_ip && (
              <Chip color="primary" label={params.row.static_ip_version}></Chip>
            )}
          </React.Fragment>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => <Status status={params.row.status} />,
      },
      {
        field: "data_used_raw",
        headerName: "Data Used",
        flex: 1,
        type: "number",
        renderCell: (params) => params.row.data_used_formatted_display,
        valueGetter: (params) => {
          return params.row.data_used_raw;
        },
      },
      {
        field: "last_data_usage_updated_at",
        headerName: "Last Data Usage Updated At",
        flex: 1,
        valueGetter: (params) =>
          params.row.last_data_usage_updated_at &&
          parseInt(moment(params.row.last_data_usage_updated_at).format("x")),
        renderCell: (params) =>
          params.row.last_data_usage_updated_at && (
            <DateAndTime dateAndTime={params.row.last_data_usage_updated_at} />
          ),
      },
      {
        field: "billing_cycle",
        headerName: "Usage Cycle / End Date",
        flex: 1,
        valueGetter: (params) => {
          if (
            params.row.billing_cycle &&
            params.row.billing_cycle.trim() !== "" &&
            params.row.billing_cycle.trim().includes("T")
          ) {
            return moment(
              params.row.billing_cycle.trim().split("T")[0],
              "YYYY-MM-DD"
            ).format("MM/DD/YYYY");
          }
          return params.row.billing_cycle;
        },
      },
    ]);

    const fetchClientDetails = () => {
        setLoading(true);
        axios.get(`/client/${client_uuid}`
        ).then(({ data }) => {
            setClientName(data.name);
        }).catch((error) => {
            setError(error.response.data.detail);
        }).finally(() => {
            setLoading(false);
        });
    }

    const fetchClientLines = () => {
        console.log(filterModel);
       
        setLoading(true);
        axios
          .get(`/client/${client_uuid}/lines`, {
            params: {
              page,
              size: pageSize,
              filters: filterModel,
              sort: sortModel?.[0],
            },
          })
          .then(({ data }) => {
            console.log(data.items);
            setClientLines(data.items);
            setRowsCount(data.total);
          })
          .catch((error) => {
            setError(error.response.data.detail);
          })
          .finally(() => {
            setLoading(false);
          });
    }

    const updateLines = (action) => {
        setLoading(true);
        let lines = linesToUpdate.split('\n');
        lines = lines.map(s => s.trim());
        axios
            .post(`/client/${client_uuid}/update-lines`, lines, { params: { action } })
            .then((response) => {
                if (response.status === 200) {
                    setSuccessMessage(response.data.message);
                    fetchClientLines();
                }
            }).catch((error) => {
                setError(error.response.data.detail);
            }).finally(() => {
                setLoading(false);
            });
    }

    const assignLines = () => {
        updateLines('assign');
    }

    const unAssignLines = () => {
        updateLines('unassign');
    }

    useEffect(() => {
        fetchClientDetails();
        fetchClientLines();
        // eslint-disable-next-line
    }, [client_uuid]);

        useEffect(() => {
          fetchClientLines();
          // eslint-disable-next-line
        }, [page, filterModel, sortModel]);

    const exportAllData = () => {
        setLoading(true);
        axios
            .get(`/client/${client_uuid}/export-lines`)
            .then(({ data }) => {
                let modifiedData = [];
                for (let lineObj of data) {
                    modifiedData.push(
                        {
                            'ICCID': `'${lineObj.iccid}`,
                            'MSISDN': lineObj.msisdn,
                            'Data Used': lineObj.data_used_formatted_display,
                            'Billing Cycle': lineObj.billing_cycle,
                            'Static Ip': lineObj.has_static_ip ? 'Yes' : '',
                            'Last Data Usage Updated At':lineObj.last_data_usage_updated_at
    
                        });
                }
                setExportData(modifiedData);
                setTimeout(() => {
                    exportDataLink.current.link.click();
                }, 500);
            }).catch((error) => {
                setError(error.response.data.detail);
            }).finally(() => {
                setLoading(false);
            });

    }
    return (
      <Layout loading={loading} error={error} successMessage={successMessage}>
        <Box mb={2} pt={2}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" align="left">
                Manage Lines for {clientName}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="top"
          spacing={2}
        >
          <Grid item xs={4}>
            <TextField
              fullWidth
              autoFocus
              margin="dense"
              id="name"
              variant="outlined"
              label="Lines"
              onChange={(e) => setLinesToUpdate(e.target.value)}
              placeholder="Enter Lines"
              minRows={5}
              multiline
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={assignLines}
              color="primary"
              disabled={loading}
            >
              Assign Lines
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={unAssignLines}
              color="secondary"
              disabled={loading}
            >
              Un-Assign Lines
            </Button>
          </Grid>
        </Grid>

        {/* {clientLines.length === 0 && (
          <Box pt={5}>
            <Typography variant="h6" align="center">
              {" "}
              No Lines Assigned.
            </Typography>
          </Box>
        )} */}

        <Box pt={2}>
          <Box pb={2}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" align="left">
                  Total Lines : {rowsCount}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={exportAllData}
                >
                  Export
                </Button>
                <CSVLink
                  data={exportData}
                  filename={
                    exportData &&
                    `${clientName}_${moment().format("YYYYMMDD_hmmss")}.csv`
                  }
                  ref={exportDataLink}
                />
              </Grid>
            </Grid>
          </Box>
          <DataGrid
            rows={clientLines}
            columns={columns.current}
            disableSelectionOnClick
            autoHeight
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onPageChange={(newPage) => setPage(newPage + 1)}
            pageSize={pageSize}
            paginationMode="server"
            rowCount={rowsCount}
            initialState={{
              pagination: {
                page: 1,
              },
            }}
            onPageSizeChange={setPageSize}
            filterMode="server"
            onFilterModelChange={(model) => {
              setFilterModel(model);
            }}
            loading={loading}
            
          />
        </Box>
      </Layout>
    );

}
export default ManageClientLines;