import React from "react";
import { Chip } from "@material-ui/core";
import { GREEN, RED, YELLOW, BLUE, GREY } from "../utils/constants";
import PropTypes from "prop-types";

const Status = (props) => {
  const { status } = props;
  return (
    <Chip
      label={status}
      size="small"
      style={{
        fontWeight: 500,
        background:
          status === "Success" || status === "ACTIVE" || status === "Active"
            ? GREEN
            : status === "Failed" || status === "ERROR"
            ? RED
            : status === "In Progress"
            ? YELLOW
            : status === "In Queue" ||
              status === "CANCELED" ||
              status === "CANCELLED"
            ? BLUE
            : status === "Lost" ||
              status === "SUSPENDED" ||
              status === "SUSPENDED-INVOLUNTARY" ||
              status === "SUSPENDED-VOLUNTARY"
            ? GREY
            : "",
        color: status === "Failed",
      }}
    />
  );
};

Status.propTypes = {
  status: PropTypes.any.isRequired,
};

export default Status;
