import React from "react";
import moment from "moment";
import { Typography, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";

const DateAndTime = (props) => {
  const { dateAndTime } = props;
  return (
    <Tooltip title={moment.utc(dateAndTime).local().format("lll")}>
      <Typography>{moment.utc(dateAndTime).local().fromNow()}</Typography>
    </Tooltip>
  );
};


DateAndTime.propTypes = {
  dateAndTime: PropTypes.any.isRequired,
};

export default DateAndTime;