import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Box, Typography, Chip, Grid, Button } from "@material-ui/core";
import moment from "moment";
import { useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import Status from "../components/Status";
import DateAndTime from "../components/DateAndTime";
import queryString from "query-string";
import { ActionsAfterSearch } from "../utils/constants";

const SimCheckStatus = (props) => {
  let { sim_check_uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [lines, setLines] = useState([]);
  const [exportData, setExportData] = useState([]);
  const exportDataLink = useRef();
  const { action } = queryString.parse(props.location.search);

  const [sortModel, setSortModel] = React.useState([
    { field: "last_updated_at", sort: "desc" },
  ]);
  const [rowsCount, setRowsCount] = useState();

  const fetchStatus = () => {
    setLoading(true);
    axios
      .get(`/sim/check/${sim_check_uuid}/status`)
      .then(({ data }) => {
     
        setRowsCount(data.length);
        setLines(data);
        let modifiedData = [];

        for (let lineObj of data) {
          let exporDataItem = {
            ICCID: `'${
              lineObj.iccid !== null && lineObj.iccid.trim() !== ""
                ? lineObj.iccid
                : ""
            }`,
           
            Status: lineObj.status,
            "Error": lineObj.error_message
          };

          modifiedData.push(exporDataItem);
        }
        setExportData(modifiedData);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStatus();
    // eslint-disable-next-line
  }, []);

  const columns = useRef([
    {
      field: "iccid",
      headerName: "ICCID",
      flex: 1,
      valueGetter: (params) => {
        return params.row.iccid;
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => <Status status={params.row.status} />,
    },

    {
      field: "last_updated_at",
      headerName: "Last Updated",
      flex: 1,
      valueGetter: (params) =>
        params.row.last_updated_at &&
        parseInt(moment(params.row.last_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_updated_at && (
          <DateAndTime dateAndTime={params.row.last_updated_at} />
        ),
    },
    {
      field: "response",
      headerName: "Error",
      minWidth: 250,
      renderCell: (params) =>
        params.row.status === "Failed" ? (
          <Typography>{params.row.error_message}</Typography>
        ) : (
          ""
        ),
    },
  ]);
  return (
    <Layout
      loading={loading}
      error={error}
      pageTitle="Sim Check Status"
      refreshPageDataFunction={fetchStatus}
    >
      <Box mb={2} pt={2}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() => {
                exportDataLink.current.link.click();
              }}
            >
              Export
            </Button>
            <CSVLink
              data={exportData}
              filename={`sim_check_status_${moment().format(
                "YYYYMMDD_hmmss"
              )}.csv`}
              ref={exportDataLink}
            />
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <DataGrid
          rows={lines}
          columns={columns.current}
          disableSelectionOnClick
          autoHeight
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          rowCount={rowsCount}
        />
      </Box>
    </Layout>
  );
};

export default SimCheckStatus;
