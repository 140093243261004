import { Box, Typography } from "@material-ui/core";
import React from "react";
import Layout from "../layouts/Layout";

const PageNotFound = () => {
  return (
    <Layout>
      <Box pt={10}>
        <Typography variant="h3" align="center">
          Page Not Found.
        </Typography>
      </Box>
    </Layout>
  );
};

export default PageNotFound;
