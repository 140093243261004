import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Button,
  Grid,
  Checkbox,
  Tooltip,
  IconButton,
  TextField,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import Layout from "../layouts/Layout";
import { useHistory } from "react-router-dom";
import AddOrUpdateCarrierAccount from "./AddOrUpdateCarrierAccount";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import moment from "moment";
import { RefreshOutlined } from "@material-ui/icons";
import DateAndTime from "../components/DateAndTime";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyIcon from "@material-ui/icons/Money";
import { makeStyles } from "@material-ui/core/styles";


const CarrierAccounts = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [carrierAccounts, setCarrierAccounts] = useState([]);
  const [sortModel, setSortModel] = useState([{
    field: "lines_updated_at",
    sort: "desc",
  }]);
  const [rowsCount, setRowsCount] = useState();
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const history = useHistory();
  const [emailSearchValue,setEmailSearchValue] = useState("");
 const [filterModel, setFilterModel] = useState();

  const fetchCarrierAccounts = () => {
    setLoading(true);
    let params = { page, size: pageSize };
    let data = { sort_model: sortModel[0] };
    if(emailSearchValue!==undefined && emailSearchValue!==null && emailSearchValue.trim()!==""){
      data.query = emailSearchValue;
    }
    axios
      .post("/carrier-accounts",data, {
        params: params,
      })
      .then(({ data }) => {
        setCarrierAccounts(data.items);
        setRowsCount(data.total);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCarrierAccounts();
    // eslint-disable-next-line
  }, [page, pageSize,emailSearchValue]);

  const updateScrapeDataUsage = (uuid, data) => {
    axios
      .put(`/carrier-account/${uuid}`, data)
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          setSuccessMessage("Carrier Account Updated Successfully.");
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        fetchCarrierAccounts();
      });
  };

  const columns = useRef([
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <Grid container justifyContent="flex-start" spacing={1}>
          <Grid item>
            <PullLinesComponent
              carrierAccountData={params.row}
              setLoading={setLoading}
              setError={setError}
            />
          </Grid>

          <Grid item>
            <Tooltip title="View Lines">
              <IconButton
                onClick={() => {
                  history.push(`/carrier-account/${params.row.uuid}/lines`);
                }}
                color="primary"
              >
                <FormatListBulletedIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title="View Billing Data">
              <IconButton
                onClick={() => {
                  history.push(
                    `/carrier-account/${params.row.uuid}/billing-data`
                  );
                }}
                color="primary"
              >
                <MonetizationOnIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          {params.row.carrier === "ATT" && (
            <Grid item>
              <Tooltip title="View Alerts">
                <IconButton
                  onClick={() => {
                    history.push(`/carrier-account/${params.row.uuid}/alerts`);
                  }}
                  color="primary"
                >
                  <NotificationImportantIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 420,

      renderCell: (params) => (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body"> {params.row.email}</Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              {params.row.total_lines -
                params.row.lines_updated_in_last_5_days >
                params.row.total_lines * 0.3 && //Greater than 30% of the lines
                params.row.scrape_data_usage && (
                  <Tooltip title="Needs attention">
                    <WarningIcon style={{ color: "red" }} />
                  </Tooltip>
                )}
              <Grid item>
                <AddOrUpdateCarrierAccount
                  setLoading={setLoading}
                  refreshCarrierAccounts={fetchCarrierAccounts}
                  carrierAccountData={params.row}
                  mode="edit"
                />
              </Grid>

              <Grid item>
                <Tooltip
                  title={`Scrape Data Usage ${
                    params.row.scrape_data_usage ? "Enabled" : "Disabled"
                  }`}
                >
                  <Checkbox
                    checked={params.row.scrape_data_usage}  
                    onChange={(e) => {
                      updateScrapeDataUsage(params.row.uuid, {
                        scrape_data_usage: e.target.checked,
                      });
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    { field: "password", headerName: "Password", minWidth: 150 },
    { field: "carrier", headerName: "Carrier", minWidth: 130 },
    {
      field: "total_lines",
      headerName: "# Total Lines",
      minWidth: 160,
    },
    {
      field: "lines_updated_in_last_24_hours",
      headerName: "# Updated in last 24 hours",
      minWidth: 160,
    },
    {
      field: "lines_updated_in_last_5_days",
      headerName: "# Updated in last 5 days",
      minWidth: 160,
    },
    {
      field: "lines_updated_at",
      headerName: "Last Lines Updated At",
      minWidth: 150,
      valueGetter: (params) =>
        params.row.lines_updated_at &&
        parseInt(moment(params.row.lines_updated_at).format("x")),
      renderCell: (params) =>
        params.row.lines_updated_at && (
          <DateAndTime dateAndTime={params.row.lines_updated_at} />
        ),
    },
    { field: "proxy", headerName: "Proxy", minWidth: 200 },
    {
      field: "secondary_proxy",
      headerName: "Secondary Proxy",
      minWidth: 200,
    },
    { field: "otp_email", headerName: "Otp Email", minWidth: 250 },
    { field: "username", headerName: "Username", minWidth: 150 },
    {
      field: "otp_phone_number",
      headerName: "OTP Phone Number",
      minWidth: 220,
    },
  ]);

  return (
    <Layout loading={loading} error={error} successMessage={successMessage}>
      <Box mb={2} pt={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Typography variant="h5" align="left">
                  Carrier Accounts
                </Typography>
              </Grid>

              <Grid item>
                <IconButton aria-label="delete" onClick={fetchCarrierAccounts}>
                  <RefreshOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id="email"
              label="Search By Email Id or Proxy"
              variant="outlined"
              fullWidth
              onChange={(e) => setEmailSearchValue(e.target.value)}
              size="small"
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<MoneyIcon />}
              onClick={() => {
                history.push("/carrier-accounts/billing-data");
              }}
            >
              View Billing Data
            </Button>
          </Grid>
          <Grid item>
            <AddOrUpdateCarrierAccount
              setLoading={setLoading}
              refreshCarrierAccounts={fetchCarrierAccounts}
              mode="new"
            />
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <div style={{ height: "80vh", overflow: "auto" }}>
          <DataGrid
            rows={carrierAccounts}
            columns={columns.current}
            disableSelectionOnClick
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onPageChange={(newPage) => setPage(newPage + 1)}
            pageSize={pageSize}
            paginationMode="server"
            rowCount={rowsCount}
            initialState={{
              pagination: {
                page: 1,
              },
            }}
            getRowId={(row) => row.uuid}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            loading={loading}
            onFilterModelChange={(model) => {
              setFilterModel(model);
            }}
            rowsPerPageOptions={[20, 40, 60, 80, 100]}
            
          />
        </div>
      </Box>
    </Layout>
  );
};

export default CarrierAccounts;

const PullLinesComponent = (props) => {
  const history = useHistory();

  const { carrierAccountData, setLoading, setError } = props;
  const { uuid, uids} = carrierAccountData;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pullLines = (carrier_account_uuid, uid) => {
    let params = {};
    if (uid !== undefined) {
      params.uid = uid;
    }
    setLoading(true);
    axios
      .get(`/carrier-account/${carrier_account_uuid}/pull-lines`, { params })
      .then(({ data }) => {
        console.log(data);
        history.push(`/line-pull/${data.uuid}/status`);
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.detail);
        setLoading(false);
      });
  };

  return uids === undefined ||
    uids === null ||
    (Array.isArray(uids) && uids.length === 0) ? (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        pullLines(uuid);
      }}
    >
      Pull Lines
    </Button>
  ) : (
    <React.Fragment>
      <Button variant="contained" color="secondary" onClick={handleClick}>
        Pull Lines
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {uids.map((uid) => (
          <MenuItem
            onClick={() => {
              pullLines(uuid, uid);
            }}
          >
            {uid}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};
