import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Typography, Box, Grid, Button, IconButton, Chip } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import Layout from "../layouts/Layout";
import { useParams } from "react-router-dom";
import DateAndTime from "../components/DateAndTime";
import UpdateIcon from "@material-ui/icons/Update";
import { RefreshOutlined } from "@material-ui/icons";
import {  useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Carriers } from "../utils/constants";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& .past-due": {
        backgroundColor: red[200],
        "&:hover": {
          backgroundColor: red[400],
        },
      },
    },
  };
});

const CarrierAccountBillingData = () => {
    const classes = useStyles();
  const { carrier_account_uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [carrierAccountData, setCarrierAccountData] = useState();
  const [clientLines, setClientLines] = useState([]);
  const [sortModel, setSortModel] = React.useState([
    { field: "last_updated_at", sort: "desc" },
  ]);
  const [rowsCount, setRowsCount] = useState();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
   const history = useHistory();
  const columns = useRef([
    {
      field: "ban",
      headerName: "BAN",
      flex: 1,
      minWidth:240,
      renderCell: (params) => (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography> {params.row.ban} </Typography>
          </Grid>
          <Grid item>
            <Chip label={params.row?.auto_pay_status} />
          </Grid>
        </Grid>
      ),
    },
    { field: "bill_amount", headerName: "Bill Amount", flex: 1 },
    { field: "bill_due_amount", headerName: "Bill Due Amount", flex: 1 },
    {
      field: "bill_cycle_start_date",
      headerName: "Bill Start Date",
      flex: 1,
      renderCell: (params) =>
        params.row.bill_cycle_start_date &&
        params.row.bill_cycle_start_date.split("T")[0],
    },
    {
      field: "bill_cycle_end_date",
      headerName: "Bill End Date",
      flex: 1,
      renderCell: (params) =>
        params.row.bill_cycle_end_date &&
        params.row.bill_cycle_end_date.split("T")[0],
    },
    {
      field: "bill_cycle_due_date",
      headerName: "Bill Due Date",
      flex: 1,
      renderCell: (params) =>
        params.row.bill_cycle_due_date &&
        params.row.bill_cycle_due_date.split("T")[0],
    },
    {
      field: "bill_media",
      headerName: "Bill Type",
      flex: 1,
    },
    {
      field: "last_updated_at",
      headerName: "Last Time Data Fetched",
      flex: 1,
      valueGetter: (params) =>
        params.row.last_updated_at &&
        parseInt(moment(params.row.last_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_updated_at && (
          <DateAndTime dateAndTime={params.row.last_updated_at} />
        ),
    },
    {
      field: "is_archived",
      headerName: "Is Archived ?",
      flex: 1,
      renderCell: (params) => (params.row.archive ? "Yes" : "No"),
    },
    {
      field: "bill_pdfs",
      headerName: "Bill PDF(s)",
      flex: 1,
      minWidth: 270,
      renderCell: (params) => (
        <Grid container direction="row" spacing={1}>
          {params.row.summary_bill_pdf && (
            <Grid item>
              <Chip
                label="Summary"
                icon={<PictureAsPdfIcon />}
                onClick={() => {
                  window.open(params.row.summary_bill_pdf, "_blank");
                }}
              ></Chip>
            </Grid>
          )}

          {params.row.detailed_bill_pdf && (
            <Grid item>
              <Chip
                label="Detail"
                icon={<PictureAsPdfIcon />}
                onClick={() => {
                  window.open(params.row.detailed_bill_pdf, "_blank");
                }}
              ></Chip>
            </Grid>
          )}
        </Grid>
      ),
    },
  ]);

  const fetchCarrierDetails = () => {
    setLoading(true);
    axios
      .get(`/carrier-account/${carrier_account_uuid}`)
      .then(({ data }) => {
        setCarrierAccountData(data);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCarrierDetails();
    // eslint-disable-next-line
  }, [carrier_account_uuid]);

  const billingData =  ()=>{
     setLoading(true);
     axios
       .get(`/carrier-accounts/billing-data`, {
         params: {
           carrier_account_uuid,
           page,
           size: pageSize,
         },
       })
       .then(({ data }) => {
         setClientLines(data.items);
         setRowsCount(data.total);
       })
       .catch((error) => {
         setError(error.response.data.detail);
       })
       .finally(() => {
         setLoading(false);
       });
  }
  useEffect(() => {
   billingData();
  }, [carrier_account_uuid, page, pageSize]);

  const fetchBillingData = () => {
    setLoading(true);
    axios
      .get(`/carrier-account/${carrier_account_uuid}/pull-billing-data`)
      .then(({ data }) => {
         history.push(`/account-billing/${data.uuid}/status`);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchBillingPDF = () => {
    setLoading(true);
    axios
      .post(`/account-billing/${carrier_account_uuid}/get_pdf`)
      .then(({ data }) => {
        setMessage("Triggered pdf pull.")
     })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout loading={loading} error={error} successMessage={message}>
      <Box mb={2} pt={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h5" align="left">
                  Billing Data for {carrierAccountData?.email} (
                  {carrierAccountData?.carrier})
                </Typography>
              </Grid>
              <Grid item>
                <IconButton aria-label="delete" onClick={billingData}>
                  <RefreshOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {
              carrierAccountData?.carrier === Carriers.TMO && 
              (
                <Button
                  style={{ marginRight: 2 }}
                  variant="contained"
                  color="primary"
                  startIcon={<UpdateIcon />}
                  onClick={fetchBillingPDF}
                >
                  Fetch PDF
                </Button>
              )
            }
            <Button
              variant="contained"
              color="primary"
              startIcon={<UpdateIcon />}
              onClick={fetchBillingData}
            >
              Get Billing Data
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box pt={2} className={classes.root}>
        <DataGrid
          rows={clientLines}
          columns={columns.current}
          disableSelectionOnClick
          autoHeight
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onPageChange={(newPage) => setPage(newPage + 1)}
          pageSize={pageSize}
          paginationMode="server"
          rowCount={rowsCount}
          initialState={{
            pagination: {
              page: 1,
            },
          }}
          onPageSizeChange={setPageSize}
          loading={loading}
          getRowClassName={(params) =>
            params.getValue(params.id, "is_past_due") ? "past-due" : ""
          }
        />
      </Box>
    </Layout>
  );
};
export default CarrierAccountBillingData;
