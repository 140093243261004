export const GREEN = "#4caf50a3";
export const RED = "#ff000073";
export const YELLOW = "#ffc107bd";
export const BLUE = "#2196f3a6";
export const GREY = "#9e9e9e";
export const Carriers = {
  ATT: "ATT",
  TMO: "TMO",
  VZN: "VZN",
};


export const ActionsAfterSearch = {
  REFRESH: "refresh",
  RESTORE: "restore",
  SUSPEND: "suspend",
  SIM_SWAP: "sim-swap",
};