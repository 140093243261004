import React, { useState, useEffect, useRef, createRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Chip,
  Grid,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import { useScreenshot } from "./../utils/ScreenshotUtils";

import ReplayIcon from "@material-ui/icons/Replay";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import Logs from "../components/Logs";
import Status from "../components/Status";
import Alert from "@material-ui/lab/Alert";
import queryString from "query-string";
import { ActionsAfterSearch } from "../utils/constants";
import CustomizedTableCell from "../components/CustomizedTableCell";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const SearchLineStatus = (props) => {
  const [loading, setLoading] = useState(false);
  let { search_uuid } = useParams();
  const [lineData, setLineData] = useState(undefined);
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const history = useHistory();
  const refreshInterval = useRef();

  const { action, new_sim } = queryString.parse(props.location.search);

  const fetchStatus = () => {
    setLoading(true);
    console.log(search_uuid);
    axios
      .get(`/line-search/${search_uuid}/status`)
      .then(({ data }) => {
        setLineData(data);
        if (data.info_message) {
          setSuccessMessage(data.info_message);
        } else if (data.error_message) {
          setError(data.error_message);
        }
        if (!["In Progress", "In Queue"].includes(data.automation_status)) {
          clearInterval(refreshInterval.current);
        }
      })
      .catch((error) => {
        clearInterval(refreshInterval.current);
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStatus();
    refreshInterval.current = setInterval(() => {
      fetchStatus();
    }, 5000);
    return () => clearInterval(refreshInterval.current);
    // eslint-disable-next-line
  }, []);

  const retryLineSearch = () => {
    setLoading(true);
    let path = "search";
    let statusPath = "status";

    if (action) {
      path = action;
      statusPath += `?action=${action}`;
    }

    if (action === ActionsAfterSearch.SIM_SWAP) {
      path += `?new_sim=${new_sim}`;
      statusPath += `&new_sim=${new_sim}`;
    }

    axios
      .get(`/line/${lineData.msisdn}/${path}`)
      .then(({ data }) => {
        history.push("/");
        history.replace(`/search-line/${data.uuid}/${statusPath}`);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const copyImageToClipboard = () =>
    takeScreenshot(ref.current).then((canvas) => {
      canvas.toBlob((blob) =>
        navigator.clipboard.write([
          new window.ClipboardItem({ "image/png": blob }),
        ])
      );
    });

  return (
    <Layout loading={loading} containerMaxWidth="md">
      {lineData && (
        <React.Fragment>
          <Box mb={2} pt={2} pb={3}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" align="left">
                  {action && action === ActionsAfterSearch.REFRESH
                    ? "Refresh Status for Line "
                    : action === ActionsAfterSearch.SUSPEND
                    ? "Suspend Status for Line "
                    : action === ActionsAfterSearch.RESTORE
                    ? "Restore Status for Line "
                    : action === ActionsAfterSearch.SIM_SWAP
                    ? "Sim Swap Status for Line "
                    : "Search Status for Line "}{" "}
                  {lineData.msisdn}
                  <Tooltip title="Take screenshot">
                    <IconButton
                      aria-label="delete"
                      onClick={copyImageToClipboard}
                    >
                      <CameraAltIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              {lineData.automation_status &&
                lineData.automation_status === "Failed" && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<ReplayIcon />}
                      onClick={retryLineSearch}
                    >
                      Retry
                    </Button>
                  </Grid>
                )}
            </Grid>
          </Box>

          {error && (
            <Box pt={3} pb={3}>
              <Alert severity="error"> {error} </Alert>
            </Box>
          )}

          {successMessage && (
            <Box pt={3} pb={3}>
              <Alert severity="success"> {successMessage} </Alert>
            </Box>
          )}

          <Box ref={ref}>
            <TableContainer component={Paper}>
              <Table size="small" style={{ tableLayout: "fixed" }}>
                <TableBody>
                  <TableRow>
                    <CustomizedTableCell>MSISDN</CustomizedTableCell>
                    <TableCell>
                      {lineData.msisdn}{" "}
                      {lineData.has_static_ip && (
                        <Chip
                          color="primary"
                          label={lineData.static_ip_version}
                        ></Chip>
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <CustomizedTableCell>Status</CustomizedTableCell>
                    <TableCell>
                      <Status
                        status={
                          lineData.status
                            ? lineData.status
                            : lineData.automation_status
                        }
                      />

                      {lineData.automation_status &&
                      lineData.automation_status === "Failed"
                        ? ` (${lineData.error})`
                        : ""}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <CustomizedTableCell>SIM Number</CustomizedTableCell>
                    <TableCell>{lineData.sim}</TableCell>
                  </TableRow>

                  <TableRow>
                    <CustomizedTableCell>IMEI</CustomizedTableCell>
                    <TableCell>{lineData.imei}</TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomizedTableCell>
                      Handset Manufacturer
                    </CustomizedTableCell>
                    <TableCell>{lineData.manufacturer_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <CustomizedTableCell>Carrier</CustomizedTableCell>
                    <TableCell>{lineData.carrier}</TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <CustomizedTableCell>Ban</CustomizedTableCell>
                    <TableCell>{lineData.ban}</TableCell>
                  </TableRow> */}

                  {lineData.product_type && (
                    <TableRow>
                      <CustomizedTableCell>Product Type</CustomizedTableCell>
                      <TableCell>{lineData.product_type}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mb={2} pt={4} pb={3}>
              <Typography variant="h5" align="left">
                Usage Data
              </Typography>
            </Box>
            <TableContainer component={Paper}>
              <Table size="small" style={{ tableLayout: "fixed" }}>
                <TableBody>
                  <TableRow>
                    <CustomizedTableCell>Data</CustomizedTableCell>
                    <TableCell>{lineData.data_usage}</TableCell>
                  </TableRow>

                  <TableRow>
                    <CustomizedTableCell>Text</CustomizedTableCell>
                    <TableCell>{lineData.text_sent}</TableCell>
                  </TableRow>

                  <TableRow>
                    <CustomizedTableCell>Voice</CustomizedTableCell>
                    <TableCell>
                      {" "}
                      {lineData.voice_used && `${lineData.voice_used} minutes`}
                    </TableCell>
                  </TableRow>
                  {lineData.billing_cycle && (
                    <TableRow>
                      <CustomizedTableCell>
                        {lineData.billing_cycle_end_date
                          ? "Usage Cycle End Date"
                          : "Usage Cycle"}
                      </CustomizedTableCell>
                      <TableCell>{lineData.billing_cycle}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </React.Fragment>
      )}

      <Logs {...props} logs={lineData && lineData.logs} />
    </Layout>
  );
};

export default SearchLineStatus;
