import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Box, Typography, Chip, Grid, Button } from "@material-ui/core";
import moment from "moment";
import { useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import Status from "../components/Status";
import DateAndTime from "../components/DateAndTime";
import queryString from "query-string";
import { ActionsAfterSearch } from "../utils/constants";

const UpdateLineStatus = (props) => {
  let { search_uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [lines, setLines] = useState([]);
  const [exportData, setExportData] = useState([]);
  const exportDataLink = useRef();
  const { action } = queryString.parse(props.location.search);

  const [sortModel, setSortModel] = React.useState([
    { field: "last_data_usage_updated_at", sort: "desc" },
  ]);
  const [rowsCount, setRowsCount] = useState();

  const fetchStatus = () => {
    setLoading(true);
    axios
      .get(`/line-update/${search_uuid}/status`)
      .then(({ data }) => {
        setRowsCount(data.length);
        setLines(data);

        let modifiedData = [];
         
        for (let lineObj of data) {
          lineObj = lineObj.line;
          let exporDataItem = {
            ICCID: `'${
              lineObj.iccid !== null && lineObj.iccid.trim() !== ""
                ? lineObj.iccid
                : ""
            }`,
            MSISDN: lineObj.msisdn,
            Status: lineObj.status,
            "Data Used": lineObj.data_used_formatted_display,
            "Billing Cycle": lineObj.billing_cycle,
            "Static Ip Version": lineObj.static_ip_version,
            "Last Data Usage Updated At": lineObj.last_data_usage_updated_at,
            "Update Line Status": lineObj.latest_search_status,
          };

          modifiedData.push(exporDataItem);
        }
        setExportData(modifiedData);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStatus();
    // eslint-disable-next-line
  }, []);

  const columns = useRef([
    {
      field: "iccid",
      headerName: "ICCID",
      flex: 1,
      valueGetter: (params) => {
        return params.row.line.iccid;
      },
    },
    {
      field: "msisdn",
      headerName: "MSISDN",
      flex: 1,
      renderCell: (params) => (
        <React.Fragment>
          {params.row.line.msisdn && (
            <Typography>{params.row.line.msisdn} &nbsp;</Typography>
          )}
          {params.row.line.has_static_ip && (
            <Chip
              color="primary"
              label={params.row.line.static_ip_version}
            ></Chip>
          )}
        </React.Fragment>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => <Status status={params.row.line.status} />,
    },
    {
      field: "data_used",
      headerName: "Data Used",
      flex: 1,
      renderCell: (params) => params.row.line.data_used_formatted_display,
      valueGetter: (params) => {
        return params.row.line.data_used_raw;
      },
    },

    {
      field: "last_data_usage_updated_at",
      headerName: "Last Data Usage Updated At",
      flex: 1,
      valueGetter: (params) =>
        params.row.line.last_data_usage_updated_at &&
        parseInt(
          moment(params.row.line.last_data_usage_updated_at).format("x")
        ),
      renderCell: (params) =>
        params.row.line.last_data_usage_updated_at && (
          <DateAndTime
            dateAndTime={params.row.line.last_data_usage_updated_at}
          />
        ),
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      flex: 1,
      valueGetter: (params) =>
        params.row.last_updated_at &&
        parseInt(moment(params.row.last_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_updated_at && (
          <DateAndTime dateAndTime={params.row.last_updated_at} />
        ),
    },
    {
      field: "billing_cycle",
      headerName: "Usage Cycle / End Date",
      flex: 1,
      valueGetter: (params) => {
        if (
          params.row.line.billing_cycle &&
          params.row.line.billing_cycle.trim() !== "" &&
          params.row.line.billing_cycle.trim().includes("T")
        ) {
          return moment(
            params.row.line.billing_cycle.trim().split("T")[0],
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        return params.row.line.billing_cycle;
      },
    },
    {
      field: "status",
      headerName: `${
        action ? action.charAt(0).toUpperCase() + action.slice(1) : "Update"
      } Line Status`,
      flex: 1,
      renderCell: (params) => <Status status={params.row.status} />,
    },
    {
      field: "response",
      headerName: "Error",
      minWidth: 250,
      renderCell: (params) =>
        params.row.status === "Failed" ? (
          <Typography>{params.row.response}</Typography>
        ) : (
          ""
        ),
    },
  ]);
  return (
    <Layout
      loading={loading}
      error={error}
      pageTitle={
        action && action === ActionsAfterSearch.SUSPEND
          ? "Line(s) Suspend Status "
          : action === ActionsAfterSearch.RESTORE
          ? "Line(s) Restore Status "
          : "Line(s) Update Stauts "
      }
      refreshPageDataFunction={fetchStatus}
    >
      <Box mb={2} pt={2}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() => {
                exportDataLink.current.link.click();
              }}
            >
              Export
            </Button>
            <CSVLink
              data={exportData}
              filename={`lines_update_status_${moment().format(
                "YYYYMMDD_hmmss"
              )}.csv`}
              ref={exportDataLink}
            />
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <DataGrid
          rows={lines}
          columns={columns.current}
          disableSelectionOnClick
          autoHeight
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          rowCount={rowsCount}
        />
      </Box>
    </Layout>
  );
};

export default UpdateLineStatus;
