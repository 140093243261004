import React from "react";
import Header from "../components/Header";
import Container from "@material-ui/core/Container";
import Loader from "../components/Loader";
import { Box, Typography, Grid, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import { RefreshOutlined } from "@material-ui/icons";

const Layout = (props) => {
  const {
    loading,
    error,
    successMessage,
    pageTitle,
    refreshPageDataFunction,
    containerMaxWidth,
  } = props;

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Header />

      <Container maxWidth={containerMaxWidth}>
        {pageTitle && (
          <Box pt={3}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Typography variant="h5" align="left">
                  {pageTitle}
                </Typography>
              </Grid>
              {refreshPageDataFunction && (
                <Grid item>
                  <IconButton
                    aria-label="delete"
                    onClick={refreshPageDataFunction}
                  >
                    <RefreshOutlined />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        {error && (
          <Box pt={3}>
            <Alert severity="error"> {error} </Alert>
          </Box>
        )}
        {successMessage && (
          <Box pt={3}>
            <Alert severity="success" > {successMessage} </Alert>
          </Box>
        )}
        {props.children}{" "}
      </Container>
    </React.Fragment>
  );
};

Layout.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  successMessage: PropTypes.string,
  pageTitle: PropTypes.string,
  refreshPageDataFunction: PropTypes.func,
  containerMaxWidth:PropTypes.string
};


Layout.defaultProps = {
  containerMaxWidth: "xl",
};

export default Layout;
