import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import PropTypes from "prop-types";

const CopyToClipboard = (props) => {
  const { data, properties, title, size, prefix } = props;
  const dataToWrite = (e) => {
    e.stopPropagation();
    let dataToCopyPaste = [];
    let properties_to_extract = [];
    if (properties === undefined || properties.length === 0) {
      properties_to_extract = Object.keys(data[0]);
    }else{
      properties_to_extract = properties;
    }
    for (const dataItem of data) {
      let dataRow = [];

      for (const property of properties_to_extract) {
        dataRow.push(prefix + dataItem[property]);
      }
      dataToCopyPaste.push(dataRow.join("\t"));
    }
    navigator.clipboard.writeText(dataToCopyPaste.join("\n"));
  };
  return (
    <Tooltip title={title}>
      <IconButton onClick={dataToWrite}>
        <FileCopy fontSize={size} color="primary" />
      </IconButton>
    </Tooltip>
  );
};

CopyToClipboard.propTypes = {
  data: PropTypes.any.isRequired,
  properties: PropTypes.array,
  title: PropTypes.string,
  size: PropTypes.string,
  prefix: PropTypes.string,
};

CopyToClipboard.defaultProps = {
  properties: [],
  title: "Copy Data",
  size: "small",
  prefix: "",
};

export default CopyToClipboard;
