import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Grid,
  Button,
  AppBar,
  Chip,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useParams, useHistory } from "react-router-dom";
import Layout from "../layouts/Layout";
import { CSVLink } from "react-csv";
import moment from "moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "./TabPanel";
import ReplayIcon from "@material-ui/icons/Replay";
import Status from "../components/Status";
import CopyToClipboard from "../components/CopyToClipboard";

const PullLineStatus = () => {
  let { search_uuid } = useParams();
  const exportDataLink = useRef();
  const [loading, setLoading] = useState(false);
  const [linePullData, setLinePullData] = useState(undefined);
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [lines, setLines] = useState([]);
  const history = useHistory();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const refreshInterval = useRef();

  const fetchStatus = () => {
    setLoading(true);
    axios
      .get(`/line-pull/${search_uuid}/status`)
      .then(({ data }) => {
        console.log(data);
        setLinePullData(data);
        if (!["In Progress", "In Queue"].includes(data.status)) {
          clearInterval(refreshInterval.current);
        }
        setLoading(false);
        if (Array.isArray(JSON.parse(data.response))) {
          setLines(JSON.parse(data.response));
        }
      })
      .catch((error) => {
        clearInterval(refreshInterval.current);
        setError(error.response.data.detail);
        setLoading(false);
      });
  };

  const addLines = () => {
    setLoading(true);
    axios
      .get(`/line-pull/${search_uuid}/add-lines`)
      .then(({ data }) => {
        setSuccessMessage(data);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStatus();
    refreshInterval.current = setInterval(() => {
      fetchStatus();
    }, 5000);
    return () => clearInterval(refreshInterval.current);
    // eslint-disable-next-line
  }, []);

  const retryLinePull = () => {
    let params = {};
    if (linePullData.uid !== undefined || linePullData.uid !== null) {
      params.uid = linePullData.uid;
    }

    setLoading(true);
    axios
      .get(`/carrier-account/${linePullData.carrier_account.uuid}/pull-lines`, {
        params,
      })
      .then(({ data }) => {
        history.push("/");
        history.replace(`/line-pull/${data.uuid}/status`);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout loading={loading} error={error} successMessage={successMessage}>
      <Container fixed>
        {linePullData && (
          <React.Fragment>
            <Box mb={2} pt={2}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5" align="left">
                    Line Pull Status
                  </Typography>
                </Grid>
                <Grid item>
                  {linePullData.status && linePullData.status === "Failed" && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ReplayIcon />}
                        onClick={retryLinePull}
                      >
                        Retry
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box mb={2} pt={2}>
              <TableContainer component={Paper}>
                <Table size="small" style={{ tableLayout: "fixed" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Carrier Email Id </TableCell>
                      <TableCell>
                        {linePullData.carrier_account.email}
                        {linePullData.uid && `(${linePullData.uid})`}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Carrier </TableCell>
                      <TableCell>
                        {linePullData.carrier_account.carrier}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Status </TableCell>
                      <TableCell>
                        {" "}
                        <Status status={linePullData.status} />{" "}
                      </TableCell>
                    </TableRow>

                    {linePullData.status &&
                      linePullData.status === "Failed" && (
                        <TableRow>
                          <TableCell>Error </TableCell>
                          <TableCell> {linePullData.response} </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {lines.length !== 0 && (
              <React.Fragment>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <CopyToClipboard
                      data={lines}
                      title="Copy All Lines"
                      size="large"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GetAppIcon />}
                      onClick={() => {
                        exportDataLink.current.link.click();
                      }}
                    >
                      Export
                    </Button>
                    <CSVLink
                      data={lines}
                      filename={
                        linePullData &&
                        `${
                          linePullData.carrier_account.email
                        }_${moment().format("YYYYMMDD_hmmss")}.csv`
                      }
                      ref={exportDataLink}
                    />
                  </Grid>
                </Grid>

                <Box pt={2} pb={2}>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={selectedTab}
                      onChange={(e, newValue) => {
                        setSelectedTab(newValue);
                      }}
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab
                        label={
                          <React.Fragment>
                            <Typography variant="body">
                              {" "}
                              All Lines <Chip label={lines.length} />
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <Tab
                        label={
                          <React.Fragment>
                            <Typography variant="body">
                              {" "}
                              New Lines{" "}
                              <Chip
                                label={linePullData.new_lines.length}
                                color="secondary"
                              />
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={selectedTab} index={0}>
                    {linePullData.carrier_account.carrier === "TMO" && (
                      <TableContainer component={Paper}>
                        <Table size="small" style={{ tableLayout: "fixed" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                MSISDN
                                <CopyToClipboard
                                  data={lines}
                                  properties={["msisdn"]}
                                  title="Copy Phone Numbers"
                                />
                              </TableCell>
                              <TableCell>Status </TableCell>
                              <TableCell>Product Type </TableCell>
                              <TableCell>Ban</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lines.map((line) => (
                              <TableRow key={line.msisdn}>
                                <TableCell>{line.msisdn}</TableCell>
                                <TableCell>
                                  <Status status={line.status} />
                                </TableCell>
                                <TableCell>{line.product_type}</TableCell>
                                <TableCell>{line.ban}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    {linePullData.carrier_account.carrier === "ATT" && (
                      <TableContainer component={Paper}>
                        <Table size="small" style={{ tableLayout: "fixed" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                MSISDN
                                <CopyToClipboard
                                  data={lines}
                                  properties={["ctnNumber"]}
                                  title="Copy Phone Numbers"
                                />
                              </TableCell>
                              <TableCell>Status </TableCell>
                              <TableCell>Name </TableCell>
                              <TableCell>New Format </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lines.map((line) => (
                              <TableRow key={line.msisdn}>
                                <TableCell>{line.ctnNumber}</TableCell>
                                <TableCell>
                                  <Status status={line.ctnStatus} />
                                </TableCell>
                                <TableCell>{line.ctnName}</TableCell>
                                <TableCell>{line.ctnNumberNew}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    {linePullData.carrier_account.carrier === "VZN" && (
                      <TableContainer component={Paper}>
                        <Table size="small" style={{ tableLayout: "fixed" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                MSISDN{" "}
                                <CopyToClipboard
                                  data={lines}
                                  properties={["msisdn"]}
                                  title="Copy Phone Numbers"
                                />
                              </TableCell>
                              <TableCell>Status </TableCell>
                              <TableCell>Ban </TableCell>
                              <TableCell>Equipment Model </TableCell>
                              <TableCell>Device Id </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lines.map((line) => (
                              <TableRow key={line.msisdn}>
                                <TableCell>{line.msisdn}</TableCell>
                                <TableCell>
                                  <Status status={line.status} />{" "}
                                  {line.suspended_date &&
                                    `On ${line.suspended_date}`}
                                </TableCell>
                                <TableCell>{line.ban}</TableCell>
                                <TableCell>{line.equipment_model}</TableCell>
                                <TableCell>{line.device_id}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </TabPanel>
                  <TabPanel value={selectedTab} index={1}>
                    {linePullData.new_lines.length === 0 && (
                      <Box pb={2}>
                        <Typography variant="h6" align="center">
                          {" "}
                          No New Lines.
                        </Typography>
                      </Box>
                    )}
                    {linePullData.new_lines.length !== 0 && (
                      <React.Fragment>
                        <Box pb={2}>
                          <Grid container justifyContent="flex-end">
                            <Grid item>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={addLines}
                              >
                                Add Lines
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <TableContainer component={Paper}>
                          <Table size="small" style={{ tableLayout: "fixed" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  MSISDN
                                  <CopyToClipboard
                                    data={linePullData.new_lines}
                                    properties={["msisdn"]}
                                    title="Copy Phone Numbers"
                                  />
                                </TableCell>
                                <TableCell>
                                  Status
                                  <CopyToClipboard
                                    data={linePullData.new_lines}
                                    properties={["status"]}
                                    title="Copy Status"
                                  />
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {linePullData.new_lines.map((line) => (
                                <TableRow key={line.msisdn}>
                                  <TableCell>{line.msisdn}</TableCell>
                                  <TableCell>
                                    <Status status={line.status} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </React.Fragment>
                    )}
                  </TabPanel>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    </Layout>
  );
};

export default PullLineStatus;
