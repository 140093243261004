import React from "react";
import "./App.css";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import SearchLine from "./containers/SearchLine";
import LinesWithDataUsage from "./containers/LinesWithDataUsage";
import LoginForm from "./containers/Login";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProfileContainer from "./containers/ProfileContainer";
import SearchLineStatus from "./containers/SearchLineStatus";
import CarrierAccounts from "./containers/CarrierAccounts";
import PullLineStatus from "./containers/PullLineStatus";
import Clients from "./containers/Clients";
import ManageClientLines from "./containers/ManageClientLines";
import ManageClientUsers from "./containers/ManageClientUsers";
import AllLines from "./containers/AllLines";
import UpdateLines from "./containers/UpdateLines";
import UpdateLineStatus from "./containers/UpdateLineStatus";
import ManageCarrierAccountLines from "./containers/ManageCarrierAccountLines";
import PageNotFound from "./containers/PageNotFound";
import UnassignedLines from "./containers/UnassignedLines";
import CarrierAccountAlerts from "./containers/CarrierAccountAlerts";
import CarrierAccountBillingData from "./containers/CarrierAccountBillingData";
import BillingData from "./containers/BillingData";
import BillingDataStatus from "./containers/BillingDataStatus";
import SimCheck from "./containers/SimCheck";
import SimCheckStatus from "./containers/SimCheckStatus";
import SimSwapPage from "./containers/SimSwap";
import SimSwapStatus from "./containers/SimSwapStatus";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1D4354",
    },
    secondary: {
      main: "#6FDA44",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colors: {
    green: "#6FDA44",
    darkBlue: "#1D4354",
    grey: "#f2f2f2",
  },
});

function App() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact={true} component={LoginForm} />
            <Route path="/search-line" exact={true} component={SearchLine} />
            <Route
              path="/search-line/:search_uuid/status"
              exact={true}
              component={SearchLineStatus}
            />
            <Route path="/profile" exact={true} component={ProfileContainer} />
            <Route
              path="/lines-data-usage"
              exact={true}
              component={LinesWithDataUsage}
            />
            <Route
              path="/carrier-accounts"
              exact={true}
              component={CarrierAccounts}
            />
            <Route
              path="/line-pull/:search_uuid/status"
              exact={true}
              component={PullLineStatus}
            />
            <Route path="/clients" exact={true} component={Clients} />
            <Route
              path="/client/:client_uuid/manage-lines"
              exact={true}
              component={ManageClientLines}
            />
            <Route
              path="/client/:client_uuid/users"
              exact={true}
              component={ManageClientUsers}
            />
            <Route path="/all-lines" exact={true} component={AllLines} />
            <Route path="/update-lines" exact={true} component={UpdateLines} />
            <Route
              path="/update-lines/:search_uuid/status"
              exact={true}
              component={UpdateLineStatus}
            />
            <Route
              path="/carrier-account/:carrier_account_uuid/lines"
              exact={true}
              component={ManageCarrierAccountLines}
            />
            <Route
              path="/carrier-account/:carrier_account_uuid/alerts"
              exact={true}
              component={CarrierAccountAlerts}
            />

            <Route
              path="/carrier-account/:carrier_account_uuid/billing-data"
              exact={true}
              component={CarrierAccountBillingData}
            />

            <Route
              path="/carrier-accounts/billing-data"
              exact={true}
              component={BillingData}
            />

            <Route
              path="/unassigned-lines"
              exact={true}
              component={UnassignedLines}
            />

            <Route
              path="/account-billing/:billing_data_uuid/status"
              exact={true}
              component={BillingDataStatus}
            />

            <Route path="/sim-check" exact={true} component={SimCheck} />
            <Route
              path="/sim-check/:sim_check_uuid/status"
              exact={true}
              component={SimCheckStatus}
            />

            <Route path="/sim-swap" exact={true} component={SimSwapPage} />
            <Route
              path="/sim/swap/:sim_swap_uuid/status"
              exact={true}
              component={SimSwapStatus}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
}

export default App;
