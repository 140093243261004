import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Button,
  Grid,
  Chip,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";
import Layout from "../layouts/Layout";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateAndTime from "../components/DateAndTime";
import Status from "../components/Status";

const ManageCarrierAccountLines = () => {
  const { carrier_account_uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  
  const [carrierAccountData, setCarrierAccountData] = useState();
  const [clientLines, setClientLines] = useState([]);
  const [sortModel, setSortModel] = React.useState([
    { field: "last_data_usage_updated_at", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = React.useState();
  const [rowsCount, setRowsCount] = useState();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  
  const [exportData, setExportData] = useState([]);
  const exportDataLink = useRef();
  const columns = useRef([
    { field: "iccid", headerName: "ICCID", flex: 1 },
    {
      field: "msisdn",
      headerName: "MSISDN",
      flex: 1,
      renderCell: (params) => (
        <React.Fragment>
          {params.row.msisdn && (
            <Typography>{params.row.msisdn} &nbsp;</Typography>
          )}
          {params.row.has_static_ip && (
            <Chip color="primary" label="Static IP"></Chip>
          )}
        </React.Fragment>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => <Status status={params.row.status} />,
    },
    {
      field: "data_used_raw",
      headerName: "Data Used",
      flex: 1,
      type: "number",
      renderCell: (params) => params.row.data_used_formatted_display,
      valueGetter: (params) => {
        return params.row.data_used_raw;
      },
    },
    {
      field: "last_data_usage_updated_at",
      headerName: "Last Data Usage Updated At",
      flex: 1,
      valueGetter: (params) =>
        params.row.last_data_usage_updated_at &&
        parseInt(moment(params.row.last_data_usage_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_data_usage_updated_at && (
          <DateAndTime dateAndTime={params.row.last_data_usage_updated_at} />
        ),
    },
    {
      field: "billing_cycle",
      headerName: "Usage Cycle / End Date",
      flex: 1,
      valueGetter: (params) => {
        if (
          params.row.billing_cycle &&
          params.row.billing_cycle.trim() !== "" &&
          params.row.billing_cycle.trim().includes("T")
        ) {
          return moment(
            params.row.billing_cycle.trim().split("T")[0],
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
        }
        return params.row.billing_cycle;
      },
    },
  ]);

  const fetchClientDetails = () => {
    setLoading(true);
    axios
      .get(`/carrier-account/${carrier_account_uuid}`)
      .then(({ data }) => {
        setCarrierAccountData(data);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchClientLines = () => {
    setLoading(true);
    axios
      .get(`/carrier-account/${carrier_account_uuid}/lines`, {
        params: {
          page,
          size: pageSize,
          filters: filterModel,
          sort: sortModel?.[0],
        },
      })
      .then(({ data }) => {
        setClientLines(data.items);
        setRowsCount(data.total);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchClientDetails();
    // eslint-disable-next-line
  }, [carrier_account_uuid]);

  useEffect(() => {
    fetchClientLines();
    // eslint-disable-next-line
  }, [page, filterModel, sortModel]);

  const exportAllData = () => {
    setLoading(true);
    axios
      .get(`/carrier-account/${carrier_account_uuid}/export-lines`)
      .then(({ data }) => {
        let modifiedData = [];
        for (let lineObj of data) {
          modifiedData.push({
            ICCID: `'${lineObj.iccid}`,
            MSISDN: lineObj.msisdn,
            "Data Used": lineObj.data_used_formatted_display,
            "Billing Cycle": lineObj.billing_cycle,
            "Static Ip Version": lineObj.static_ip_version,
            "Last Data Usage Updated At": lineObj.last_data_usage_updated_at,
          });
        }
        setExportData(modifiedData);
        setTimeout(() => {
          exportDataLink.current.link.click();
        }, 500);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Layout loading={loading} error={error}>
      <Box mb={2} pt={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" align="left">
              Lines for {carrierAccountData?.email} (
              {carrierAccountData?.carrier})
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <Box pb={2}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" align="left">
                Total Lines : {rowsCount}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={exportAllData}
              >
                Export
              </Button>
              <CSVLink
                data={exportData}
                filename={
                  exportData &&
                  `${carrierAccountData?.email}_${moment().format(
                    "YYYYMMDD_hmmss"
                  )}.csv`
                }
                ref={exportDataLink}
              />
            </Grid>
          </Grid>
        </Box>
        <DataGrid
          rows={clientLines}
          columns={columns.current}
          disableSelectionOnClick
          autoHeight
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onPageChange={(newPage) => setPage(newPage + 1)}
          pageSize={pageSize}
          paginationMode="server"
          rowCount={rowsCount}
          initialState={{
            pagination: {
              page: 1,
            },
          }}
          onPageSizeChange={setPageSize}
          filterMode="server"
          onFilterModelChange={(model) => {
            setFilterModel(model);
          }}
          loading={loading}
        />
      </Box>
    </Layout>
  );
};
export default ManageCarrierAccountLines;
