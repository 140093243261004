import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import Layout from "../layouts/Layout";

const SearchLine = (props) => {
  const [loading, setLoading] = useState(false);
  const [lineId, setLineId] = useState("");
  const [error, setError] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const searchLineWithId = (line_id) => {
    setLoading(true);
    axios
      .get(`/line/${line_id}/search`)
      .then(({ data }) => {
        setError(undefined);
        setLoading(false);
        history.push(`/search-line/${data.uuid}/status`);
      })
      .catch((error) => {
        setError(error.response.data.detail);
        setLoading(false);
      });
  };

  const searchLine = () => {
    if (lineId !== "") {
      searchLineWithId(lineId);
    }
  };

  useEffect(() => {
    const filterValues = queryString.parse(location.search);
    console.log(filterValues);
    if (filterValues.lineId) {
      setLineId(filterValues.lineId);
      searchLineWithId(filterValues.lineId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      error={error}
      loading={loading}
      pageTitle={props.title}
      containerMaxWidth="lg"
    >
      <Box mb={2} pt={4}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          spacing={4}
          alignItems="center"
        >
          <Grid item xs={12} lg={4}>
            <TextField
              id="line_id"
              label="Line ID (SIM or MSISDN)"
              variant="outlined"
              fullWidth
              value={lineId}
              onChange={(e) => setLineId(e.target.value)}
              size="small"
            />
          </Grid>

          <Grid item>
            <Button variant="contained" color="primary" onClick={searchLine}>
              Search Line
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

SearchLine.defaultProps = {
  title: "Search Line",
};

export default SearchLine;
