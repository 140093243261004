import React, { useState, useEffect } from "react";

import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import queryString from "query-string";
import TableHeadCell from "./TableHeadCell";

const Logs = (props) => {
  const params = queryString.parse(props.location.search);

  const [logs, setLogs] = useState();

  useEffect(() => {
    if (props.logs) {
      
      setLogs(JSON.parse(props.logs));
    }
  }, [props.logs]);

  return (
    params &&
    params["logs"] === "1" && (
      <Box pt={2}>
        <Typography variant="h5">Logs</Typography>
        <Box pt={2}>
          <TableContainer component={Paper}>
            <Table size="small" style={{ tableLayout: "fixed" }}>
              <TableBody>
                <TableRow>
                  <TableHeadCell>Date & Time</TableHeadCell>
                  <TableHeadCell>Message</TableHeadCell>
                </TableRow>

                {logs &&
                  logs.map((log) => (
                    <TableRow>
                      <TableCell>{log.date}</TableCell>
                      <TableCell>{log.message}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    )
  );
};

export default Logs;
