import React, { useState } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import Layout from "../layouts/Layout";
import UpdateIcon from "@material-ui/icons/Update";
import { useHistory } from "react-router-dom";
import { ActionsAfterSearch } from "../utils/constants";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import RestoreIcon from "@material-ui/icons/Restore";

const UpdateLines = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [linesToUpdate, setLinesToUpdate] = useState("");
  const history = useHistory();

  const updateLines = (action) => {
    setLoading(true);
    let lines = linesToUpdate.split("\n");
    lines = lines.map((s) => s.trim());
    const data = {lines};
    if (action){
      data["action"] = action;
    }
      axios
        .post(`lines/update`, data)
        .then((response) => {
          if (response.status === 200) {
            if(action){
            history.push(`/update-lines/${response.data.uuid}/status?action=${action}`);

            }else{
            history.push(`/update-lines/${response.data.uuid}/status`);

            }
          }
        })
        .catch((error) => {
          console.log(error.response.data.detail);
          setError(error.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  return (
    <Layout loading={loading} error={error}>
      <Box mb={2} pt={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" align="left">
              Update Lines
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={4}
          alignItems="top"
        >
          <Grid item xs={6}>
            <TextField
              id="line_id"
              label="Line ID (SIM or MSISDN)"
              variant="outlined"
              fullWidth
              value={linesToUpdate}
              onChange={(e) => setLinesToUpdate(e.target.value)}
              size="small"
              minRows={5}
              multiline
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<UpdateIcon />}
              onClick={() => {
                updateLines();
              }}
            >
              Update Lines
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<NotInterestedIcon fontSize="small" color="error" />}
              onClick={() => {
                updateLines(ActionsAfterSearch.SUSPEND);
              }}
            >
              Suspend Lines
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<RestoreIcon fontSize="small" color="secondary" />}
              onClick={() => {
                updateLines(ActionsAfterSearch.RESTORE);
              }}
            >
              Restore Lines
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default UpdateLines;
